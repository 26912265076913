import React, {Component, useEffect, useState} from "react";
import {
    Alert,
    AppBar,
    Box,
    Button, Checkbox, Collapse, Container, Divider,
    Drawer, Fab, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid,
    IconButton, InputLabel,
    Link,
    List,
    ListItem, ListItemButton,
    ListItemText, ListSubheader, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Stack,
    Toolbar,
    useScrollTrigger, Zoom
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import RefreshIcon from '@mui/icons-material/Refresh';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {Color, ColorBox, ColorValue, createColor} from "mui-color";
import {V3DWeb, CloneableQuaternionMap, HolisticOptions} from "v3d-web/dist/src";


export class Privacy extends Component {

    render() {
        return (
            <div className={`bg-background grid h-screen w-screen gap-y-16 overflow-hidden`}>
                <div className="max-w-7xl max-h-4 mx-auto z-10">
                    <Container>
                        <Box sx={{my: 2, color: "black"}}>
                            <div style={{whiteSpace: 'pre-wrap'}}>
                                We do not collect any user data. Period.{"\n"}
                                See {
                                <Link
                                    href={"https://docs.github.com/en/github/site-policy/github-privacy-statement"}
                                    underline={"hover"} rel="noreferrer">Github Privacy Statement</Link>} for Github's
                                privacy policy.
                            </div>
                        </Box>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Privacy;
