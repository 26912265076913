import React, {Fragment} from 'react';

// import { Popover, Transition } from '@headlessui/react';
// import { MenuIcon, XIcon } from '@heroicons/react/outline';
// import { Link } from 'react-scroll';

import config from '../config/index.json';
import {
    AppBar,
    Box,
    Button, Drawer,
    IconButton, Link, List, ListItem, ListItemText,
    Menu,
    MenuItem,
    Slide,
    Toolbar,
    Typography,
    useScrollTrigger
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 240;

function HideOnScroll({children}: { children: React.ReactElement }) {
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const Header = () => {
    const {navigation, company, callToAction} = config;
    const {name: companyName, logo} = company;

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <List>
                {navigation.map((item) => (
                    <ListItem
                        button
                        key={item.name}
                        component={Link}
                        href={item.href}
                        underline="none"
                    >
                        <ListItemText primary={item.name}/>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    const container = window !== undefined ? () => document.body : undefined;

    return (
        <>
            <svg
                className={`hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-background transform translate-x-1/2`}
                fill="currentColor"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                aria-hidden="true"
            >
                <polygon points="50,0 100,0 50,100 0,100"/>
            </svg>
            <nav>
                <Box sx={{flexGrow: 1}}>
                    <AppBar enableColorOnDark>
                        <Toolbar>

                            <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                    sx={{mr: 2, display: {md: 'none'}}}
                                >
                                    <MenuIcon/>
                                </IconButton>
                                <Drawer
                                    container={container}
                                    variant="temporary"
                                    open={mobileOpen}
                                    onClose={handleDrawerToggle}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}
                                    sx={{
                                        display: {xs: 'block', md: 'none'},
                                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                                    }}
                                >
                                    {drawer}
                                </Drawer>
                            </Box>

                            <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                                {navigation.map((item) => (
                                    <Button
                                        key={item.name}
                                        onClick={handleCloseNavMenu}
                                        sx={{my: 2, color: 'white', display: 'block'}}
                                        component={Link}
                                        href={item.href}
                                        underline="none"
                                    >
                                        {item.name}
                                    </Button>
                                ))}
                            </Box>

                        </Toolbar>
                    </AppBar>
                </Box>
            </nav>
        </>
    );
};

export default Header;
