import { Vector3 } from '@babylonjs/core/Maths/math';
/**
 * Vector3 Helper
 */
export class Vector3Helper {
    /**
     * Vector3 * float
     *
     * @param original
     * @param amount
     */
    static multiplyByFloat(original, amount) {
        return new Vector3(original.x * amount, original.y * amount, original.z * amount);
    }
}
