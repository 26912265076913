import { GLTFFileLoader } from '@babylonjs/loaders/glTF/glTFFileLoader';
/**
 * VRM/VCI ファイルを読み込めるようにする
 * 拡張子を変更しただけ
 */
export class VRMFileLoader extends GLTFFileLoader {
    constructor() {
        super(...arguments);
        this.name = 'vrm';
        this.extensions = {
            '.vrm': { isBinary: true },
            '.vci': { isBinary: true },
        };
        this.vrmManager = null;
    }
    createPlugin() {
        return new VRMFileLoader();
    }
    loadAsync(scene, data, rootUrl, onProgress, fileName) {
        this.uri = rootUrl;
        if (fileName)
            this.uri += fileName;
        return super.loadAsync(scene, data, rootUrl, onProgress, fileName);
    }
}
