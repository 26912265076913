import { Vector3 } from '@babylonjs/core/Maths/math';
import { SpringBoneController } from './secondary-animation/spring-bone-controller';
import { HumanoidBone } from './humanoid-bone';
export class morphingTargetProperty {
    constructor(label, value, manager) {
        this.label = label;
        this.manager = manager;
        this._value = value;
    }
    get value() {
        return this._value;
    }
    set value(value) {
        this._value = Math.max(0, Math.min(1, value));
        this.manager.morphing(this.label, value);
    }
}
/**
 * VRM キャラクターを動作させるためのマネージャ
 */
export class VRMManager {
    /**
     *
     * @param ext glTF.extensions.VRM の中身 json
     * @param scene
     * @param meshesFrom この番号以降のメッシュがこの VRM に該当する
     * @param transformNodesFrom この番号以降の TransformNode がこの VRM に該当する
     * @param uri URI this manager belongs to
     */
    constructor(ext, scene, meshesFrom, transformNodesFrom, uri) {
        this.ext = ext;
        this.scene = scene;
        this.meshesFrom = meshesFrom;
        this.transformNodesFrom = transformNodesFrom;
        this.uri = uri;
        this.morphTargetMap = {};
        this.presetMorphTargetMap = {};
        this.transformNodeMap = {};
        this.transformNodeCache = {};
        this.meshCache = {};
        this._cameras = [];
        /**
         * This is necessary because of the way BabylonJS animation works
         */
        this.MorphTargetPropertyMap = {};
        this.meshCache = this.constructMeshCache();
        this.transformNodeCache = this.constructTransformNodeCache();
        this.springBoneController = new SpringBoneController(this.ext.secondaryAnimation, this.findTransformNode.bind(this));
        this.springBoneController.setup();
        this.constructMorphTargetMap();
        this.constructTransformNodeMap();
        this._humanoidBone = new HumanoidBone(this.transformNodeMap);
        this.removeDuplicateSkeletons();
        this._rootSkeleton = this.getRootSkeletonNode();
        // Rename __root__ node
        this.rootMesh.name = VRMManager.ROOT_MESH_PREFIX +
            this.scene.getNodes().filter(e => e.name.includes(VRMManager.ROOT_MESH_PREFIX)).length;
    }
    get transformNodeTree() {
        return this._transformNodeTree;
    }
    get cameras() {
        return this._cameras;
    }
    appendCamera(camera) {
        this._cameras.push(camera);
    }
    resetCameras() {
        this._cameras = [];
    }
    /**
     * Remove duplicate skeletons when importing VRM.
     * Only tested on VRoidStudio output files.
     * @private
     */
    removeDuplicateSkeletons() {
        let skeleton = null;
        for (const nodeIndex of Object.keys(this.meshCache).map(Number)) {
            const meshes = this.meshCache[nodeIndex];
            if (meshes.length && meshes[0].skeleton) {
                if (!skeleton) {
                    skeleton = meshes[0].skeleton;
                    if (this._rootMesh) {
                        const rootBone = skeleton.bones[0];
                        // Usually it is called "Root", but there are exceptions
                        if (rootBone.name !== "Root")
                            console.warn('The first bone has a different name than "Root"');
                    }
                }
                else {
                    // weak sanity check
                    if (skeleton.bones.length != meshes[0].skeleton.bones.length)
                        console.warn("Skeletons have different numbers of bones!");
                    meshes[0].skeleton.dispose();
                    for (const mesh of meshes) {
                        mesh.skeleton = skeleton;
                    }
                }
            }
        }
    }
    /**
     * Find the root node of skeleton.
     * @private
     */
    getRootSkeletonNode() {
        const rootMeshChildren = this._rootMesh.getChildren((node) => {
            return node.name === "Root" || node.name === "Armature";
        });
        if (rootMeshChildren.length > 0)
            return rootMeshChildren[0];
        else {
            // Try to find in scene directly
            const rootMeshChild = this.scene.getNodeByName("Root")
                ? this.scene.getNodeByName("Root")
                : this.scene.getNodeByName("Armature");
            if (rootMeshChild && !rootMeshChild.parent)
                return rootMeshChild;
            else
                throw Error("Cannot find root skeleton node!");
        }
    }
    /**
     * Secondary Animation を更新する
     *
     * @param deltaTime 前フレームからの経過秒数(sec)
     * @param boneOptions
     */
    async update(deltaTime, boneOptions) {
        await this.springBoneController.update(deltaTime, boneOptions);
    }
    /**
     * 破棄処理
     */
    dispose() {
        this.springBoneController.dispose();
        this._humanoidBone.dispose();
        this._rootSkeleton.dispose();
        if (this._rootMesh)
            this._rootMesh.dispose();
        this.morphTargetMap = null;
        this.MorphTargetPropertyMap = null;
        this.presetMorphTargetMap = null;
        this.transformNodeMap = null;
        this.transformNodeCache = null;
        this.meshCache = null;
        this._cameras = null;
        this._transformNodeTree = null;
        this._rootMesh = null;
    }
    /**
     * モーフィングを行う
     * @param label モーフ名
     * @param value 値(0〜1)
     */
    morphing(label, value) {
        if (!this.morphTargetMap[label]) {
            return;
        }
        this.morphTargetMap[label].forEach((setting) => {
            setting.target.influence = Math.max(0, Math.min(1, value)) * (setting.weight / 100);
        });
    }
    /**
     * プリセットモーフのモーフィングを行う
     * @param label モーフ名
     * @param value 値(0〜1)
     */
    morphingPreset(label, value) {
        if (!this.presetMorphTargetMap[label]) {
            return;
        }
        this.presetMorphTargetMap[label].forEach((setting) => {
            setting.target.influence = Math.max(0, Math.min(1, value)) * (setting.weight / 100);
        });
    }
    /**
     * list morphing name
     */
    getMorphingList() {
        return Object.keys(this.morphTargetMap);
    }
    /**
     * 一人称時のカメラ位置を絶対座標として取得する
     *
     * firstPersonBone が未設定の場合は null を返す
     *
     * @returns 一人称時のカメラの現在における絶対座標
     */
    getFirstPersonCameraPosition() {
        const firstPersonBone = this.getFirstPersonBone();
        if (!firstPersonBone) {
            return null;
        }
        let basePos = firstPersonBone.getAbsolutePosition();
        const offsetPos = this.ext.firstPerson.firstPersonBoneOffset;
        return new Vector3(basePos.x + offsetPos.x, basePos.y + offsetPos.y, basePos.z + offsetPos.z);
    }
    /**
     * 一人称時に頭とみなす TransformNode を取得する
     */
    getFirstPersonBone() {
        return this.findTransformNode(this.ext.firstPerson.firstPersonBone);
    }
    /**
     * Get HumanoidBone Methods
     */
    get humanoidBone() {
        return this._humanoidBone;
    }
    /**
     * VRM Root mesh
     *
     * Useful for Model Transformation
     */
    get rootMesh() {
        return this._rootMesh;
    }
    get rootSkeletonNode() {
        return this._rootSkeleton;
    }
    /**
     * node 番号から該当する TransformNode を探す
     * 数が多くなるのでキャッシュに参照を持つ構造にする
     * gltf の node 番号は `metadata.gltf.pointers` に記録されている
     * @param nodeIndex
     */
    findTransformNode(nodeIndex) {
        return this.transformNodeCache[nodeIndex] || null;
    }
    /**
     * Find index of s specific TransformNode from cache
     * @param node
     */
    indexOfTransformNode(node) {
        for (const [k, v] of Object.entries(this.transformNodeCache)) {
            if (node == v)
                return parseInt(k, 10);
        }
        return -1;
    }
    /**
     * mesh 番号からメッシュを探す
     * gltf の mesh 番号は `metadata.gltf.pointers` に記録されている
     */
    findMeshes(meshIndex) {
        return this.meshCache[meshIndex] || null;
    }
    /**
     * 事前に MorphTarget と BlendShape を紐付ける
     */
    constructMorphTargetMap() {
        if (!this.ext.blendShapeMaster || !this.ext.blendShapeMaster.blendShapeGroups) {
            return;
        }
        this.ext.blendShapeMaster.blendShapeGroups.forEach((g) => {
            if (!g.binds) {
                return;
            }
            g.binds.forEach((b) => {
                const meshes = this.findMeshes(b.mesh);
                if (!meshes) {
                    console.log(`Undefined BlendShapeBind Mesh`, b);
                    return;
                }
                meshes.forEach((mesh) => {
                    const morphTargetManager = mesh.morphTargetManager;
                    if (!morphTargetManager) {
                        console.log(`Undefined morphTargetManager`, b);
                        return;
                    }
                    const target = morphTargetManager.getTarget(b.index);
                    this.morphTargetMap[g.name] = this.morphTargetMap[g.name] || [];
                    this.morphTargetMap[g.name].push({
                        target,
                        weight: b.weight,
                    });
                    this.MorphTargetPropertyMap[g.name] = new morphingTargetProperty(g.name, 0., this);
                    if (g.presetName) {
                        this.presetMorphTargetMap[g.presetName] = this.presetMorphTargetMap[g.presetName] || [];
                        this.presetMorphTargetMap[g.presetName].push({
                            target,
                            weight: b.weight,
                        });
                    }
                });
            });
            // TODO: materialValues
        });
    }
    /**
     * 事前に TransformNode と bone 名を紐づける
     */
    constructTransformNodeMap() {
        const treePreArr = [];
        this.ext.humanoid.humanBones.forEach((b) => {
            const node = this.findTransformNode(b.node);
            if (!node) {
                return;
            }
            this.transformNodeMap[b.bone] = node;
            treePreArr.push({ id: b.node, name: b.bone, parent: this.indexOfTransformNode(node.parent) });
        });
        const tree = this.hierarchy(treePreArr);
        if (tree.length === 0)
            throw Error("Failed to construct bone hierarchy tree!");
        this._transformNodeTree = tree[0];
    }
    hierarchy(data) {
        const tree = [];
        const childOf = {};
        data.forEach((item) => {
            const id = item.id;
            const parent = item.parent;
            childOf[id] = childOf[id] || [];
            item.children = childOf[id];
            // Assume Hips is root
            if (parent != null && this.transformNodeCache[parent].parent != this._rootMesh
                && item.name.toLowerCase() !== 'hips') {
                (childOf[parent] = childOf[parent] || []).push(item);
            }
            else {
                tree.push(item);
            }
        });
        return tree;
    }
    /**
     * node 番号と TransformNode を紐づける
     */
    constructTransformNodeCache() {
        const cache = {};
        for (let index = this.transformNodesFrom; index < this.scene.transformNodes.length; index++) {
            const node = this.scene.transformNodes[index];
            // ポインタが登録されていないものは省略
            if (!node || !node.metadata || !node.metadata.gltf || !node.metadata.gltf.pointers || node.metadata.gltf.pointers.length === 0) {
                continue;
            }
            for (const pointer of node.metadata.gltf.pointers) {
                if (pointer.startsWith('/nodes/')) {
                    const nodeIndex = parseInt(pointer.substring(7), 10);
                    cache[nodeIndex] = node;
                    break;
                }
            }
        }
        return cache;
    }
    /**
     * mesh 番号と Mesh を紐づける
     */
    constructMeshCache() {
        const cache = {};
        for (let index = this.meshesFrom; index < this.scene.meshes.length; index++) {
            const mesh = this.scene.meshes[index];
            if (mesh.id === '__root__') {
                this._rootMesh = mesh;
                continue;
            }
            // ポインタが登録されていないものは省略
            if (!mesh || !mesh.metadata || !mesh.metadata.gltf || !mesh.metadata.gltf.pointers || mesh.metadata.gltf.pointers.length === 0) {
                continue;
            }
            for (const pointer of mesh.metadata.gltf.pointers) {
                const match = pointer.match(/^\/meshes\/(\d+).+$/);
                if (match) {
                    const nodeIndex = parseInt(match[1], 10);
                    cache[nodeIndex] = cache[nodeIndex] || [];
                    cache[nodeIndex].push(mesh);
                    break;
                }
            }
        }
        return cache;
    }
    /**
     * Set whether shadow are received.
     * @param enabled
     */
    setShadowEnabled(enabled) {
        for (const nodeIndex of Object.keys(this.meshCache).map(Number)) {
            const meshes = this.meshCache[nodeIndex];
            for (const mesh of meshes) {
                mesh.receiveShadows = enabled;
            }
        }
    }
}
VRMManager.ROOT_MESH_PREFIX = "vrm_root_";
