import { Material } from '@babylonjs/core/Materials/material';
import { Color3 } from '@babylonjs/core/Maths/math';
import { MToonMaterial } from '../../../shader/babylon-mtoon-material/src';
import { IVRMMaterialPropertyShader } from './vrm-interfaces';
import { Engine } from '@babylonjs/core/Engines/engine';
/**
 * VRM で指定される Material を生成する
 * [VRM が提供するシェーダ](https://vrm.dev/vrm_spec/#vrm%E3%81%8C%E6%8F%90%E4%BE%9B%E3%81%99%E3%82%8B%E3%82%B7%E3%82%A7%E3%83%BC%E3%83%80%E3%83%BC) を特定し読み込む
 * - UnlitTexture: 不透明, VRM ファイル側で [KHR_materials_unlit](https://github.com/KhronosGroup/glTF/tree/master/extensions/2.0/Khronos/KHR_materials_unlit/) が定義されているため、何もしない
 * - UnlitCutout: 透明度が閾値以下の部分を透明とする, 同上
 * - UnlitTransparent: アルファブレンド。ZWriteしない, 同上
 * - UnlitTransparentZWrite: アルファブレンド。ZWriteする, 同上に加え、プロパティで ZWrite を強制しています
 * - MToon: MToonMaterial を差し替えています。
 */
export class VRMMaterialGenerator {
    /**
     * @inheritdoc
     */
    constructor(loader) {
        this.loader = loader;
    }
    /**
     * マテリアルを生成する Promise を返す
     * VRM 対象外の場合は null
     */
    generate(context, material, mesh, babylonDrawMode, assign) {
        const materialProp = this.findMaterialPropertyByName(material.name, this.getMaterialProperties());
        if (!materialProp) {
            return null;
        }
        mesh.alphaIndex = materialProp.renderQueue;
        const newMaterial = this.createMaterialByShader(context, material, babylonDrawMode, materialProp);
        if (!newMaterial) {
            return null;
        }
        assign(newMaterial);
        if (newMaterial instanceof MToonMaterial) {
            return this.loadMToonTexturesAsync(context, newMaterial, materialProp);
        }
        return Promise.resolve(newMaterial);
    }
    /**
     * VRM または VCI からマテリアルプロパティの配列を探す
     */
    getMaterialProperties() {
        if (!this.loader.gltf.extensions) {
            return [];
        }
        if (this.loader.gltf.extensions.VRM && this.loader.gltf.extensions.VRM.materialProperties) {
            return this.loader.gltf.extensions.VRM.materialProperties;
        }
        if (this.loader.gltf.extensions.VCAST_vci_material_unity && this.loader.gltf.extensions.VCAST_vci_material_unity.materials) {
            return this.loader.gltf.extensions.VCAST_vci_material_unity.materials;
        }
        return [];
    }
    /**
     * マテリアル名から MaterialProperty を探す
     * @param materialName マテリアル名
     * @param extension 拡張データ
     */
    findMaterialPropertyByName(materialName, materials) {
        if (!materialName || !materials) {
            return null;
        }
        const mats = materials.filter((v) => v.name === materialName);
        if (mats.length === 0) {
            return null;
        }
        else if (mats.length >= 2) {
            this.loader.log(`Duplicated vrm material name found: ${materialName}`);
        }
        return mats[mats.length - 1];
    }
    /**
     * テクスチャを読み込む
     * @param context 現在のコンテキスト
     * @param material 生成した MToonMaterial
     * @param prop 生成した MToonMaterial のマテリアルプロパティ
     */
    loadMToonTexturesAsync(context, material, prop) {
        const promises = [];
        // 全てのテクスチャの UV Offset & Scale はメインテクスチャのものを流用する
        const uvOffsetScale = prop.vectorProperties._MainTex;
        if (!uvOffsetScale) {
            return Promise.resolve(material);
        }
        const applyTexture = (index, callback) => {
            applyPropertyWhenDefined(index, (value) => {
                promises.push(this.loader.loadTextureInfoAsync(`${context}/textures/${index}`, { index: value }, (babylonTexture) => {
                    // 実際は Texture インスタンスが来るのでキャスト
                    const t = babylonTexture;
                    t.uOffset = uvOffsetScale[0];
                    t.vOffset = uvOffsetScale[1];
                    t.uScale = uvOffsetScale[2];
                    t.vScale = uvOffsetScale[3];
                    callback(babylonTexture);
                }));
            });
        };
        applyTexture(prop.textureProperties._MainTex, (texture) => {
            material.diffuseTexture = texture;
            if (material.transparencyMode)
                material.diffuseTexture.hasAlpha = true;
        });
        applyTexture(prop.textureProperties._ShadeTexture, (texture) => material.shadeTexture = texture);
        applyTexture(prop.textureProperties._BumpMap, (texture) => material.bumpTexture = texture);
        applyTexture(prop.textureProperties._ReceiveShadowTexture, (texture) => material.receiveShadowTexture = texture);
        applyTexture(prop.textureProperties._ShadingGradeTexture, (texture) => material.shadingGradeTexture = texture);
        applyTexture(prop.textureProperties._RimTexture, (texture) => material.rimTexture = texture);
        applyTexture(prop.textureProperties._SphereAdd, (texture) => material.matCapTexture = texture);
        applyTexture(prop.textureProperties._EmissionMap, (texture) => material.emissiveTexture = texture);
        applyTexture(prop.textureProperties._OutlineWidthTexture, (texture) => material.outlineWidthTexture = texture);
        applyTexture(prop.textureProperties._UvAnimMaskTexture, (texture) => material.uvAnimationMaskTexture = texture);
        return Promise.all(promises).then(() => material);
    }
    /**
     * シェーダ名からマテリアルを推測して生成する
     * @param context 現在のコンテキスト
     * @param material glTF マテリアル
     * @param babylonDrawMode 描画種類
     * @param prop 生成するマテリアルプロパティ
     */
    createMaterialByShader(context, material, babylonDrawMode, prop) {
        if (prop.shader === IVRMMaterialPropertyShader.VRMMToon) {
            const mtoonMaterial = new MToonMaterial(material.name || `MToonMaterial${material.index}`, this.loader.babylonScene);
            this.setMToonMaterialProperties(mtoonMaterial, prop);
            return mtoonMaterial;
        }
        if (prop.shader === IVRMMaterialPropertyShader.VRMUnlitTransparentZWrite) {
            const mat = this.loader.createMaterial(context, material, babylonDrawMode);
            // 通常マテリアルに Depth Write を強制
            mat.disableDepthWrite = false;
            mat.forceDepthWrite = true;
            return mat;
        }
        return null;
    }
    /**
     * マテリアルに VRM プロパティを設定
     * VRM プロパティとマテリアルプロパティのマッピングを行っている
     * 初期値はマテリアル実装側に持っているため、値がある場合のみ上書きする
     */
    setMToonMaterialProperties(material, prop) {
        applyPropertyWhenDefined(prop.floatProperties._Cutoff, (value) => material.alphaCutOff = value);
        applyPropertyWhenDefined(prop.vectorProperties._Color, (value) => {
            material.diffuseColor = new Color3(value[0], value[1], value[2]);
            material.alpha = value[3];
        });
        applyPropertyWhenDefined(prop.vectorProperties._ShadeColor, (value) => {
            material.shadeColor = new Color3(value[0], value[1], value[2]);
        });
        applyPropertyWhenDefined(prop.floatProperties._BumpScale, (value) => material.bumpScale = value);
        applyPropertyWhenDefined(prop.floatProperties._ReceiveShadowRate, (value) => material.receiveShadowRate = value);
        applyPropertyWhenDefined(prop.floatProperties._ShadingGradeRate, (value) => material.shadingGradeRate = value);
        applyPropertyWhenDefined(prop.floatProperties._ShadeShift, (value) => material.shadeShift = value);
        applyPropertyWhenDefined(prop.floatProperties._ShadeToony, (value) => material.shadeToony = value);
        applyPropertyWhenDefined(prop.floatProperties._LightColorAttenuation, (value) => material.lightColorAttenuation = value);
        applyPropertyWhenDefined(prop.floatProperties._IndirectLightIntensity, (value) => material.indirectLightIntensity = value);
        applyPropertyWhenDefined(prop.vectorProperties._RimColor, (value) => {
            material.rimColor = new Color3(value[0], value[1], value[2]);
        });
        applyPropertyWhenDefined(prop.floatProperties._RimLightingMix, (value) => material.rimLightingMix = value);
        applyPropertyWhenDefined(prop.floatProperties._RimFresnelPower, (value) => material.rimFresnelPower = value);
        applyPropertyWhenDefined(prop.floatProperties._RimLift, (value) => material.rimLift = value);
        applyPropertyWhenDefined(prop.vectorProperties._EmissionColor, (value) => {
            material.emissiveColor = new Color3(value[0], value[1], value[2]);
        });
        applyPropertyWhenDefined(prop.floatProperties._OutlineWidth, (value) => material.outlineWidth = value);
        applyPropertyWhenDefined(prop.floatProperties._OutlineScaledMaxDistance, (value) => material.outlineScaledMaxDistance = value);
        applyPropertyWhenDefined(prop.vectorProperties._OutlineColor, (value) => {
            material.outlineColor = new Color3(value[0], value[1], value[2]);
        });
        applyPropertyWhenDefined(prop.floatProperties._OutlineLightingMix, (value) => material.outlineLightingMix = value);
        applyPropertyWhenDefined(prop.floatProperties._UvAnimScrollX, (value) => material.uvAnimationScrollX = value);
        applyPropertyWhenDefined(prop.floatProperties._UvAnimScrollY, (value) => material.uvAnimationScrollY = value);
        applyPropertyWhenDefined(prop.floatProperties._UvAnimRotation, (value) => material.uvAnimationRotation = value);
        applyPropertyWhenDefined(prop.floatProperties._DebugMode, (value) => material.debugMode = value);
        applyPropertyWhenDefined(prop.floatProperties._BlendMode, (value) => {
            switch (value) {
                case 0: // Opaque
                    material.transparencyMode = Material.MATERIAL_OPAQUE;
                    break;
                case 1: // TransparentCutout
                    material.transparencyMode = Material.MATERIAL_ALPHATEST;
                    material.alphaMode = Engine.ALPHA_COMBINE;
                    break;
                case 2: // Transparent
                    material.transparencyMode = Material.MATERIAL_ALPHABLEND;
                    material.alphaMode = Engine.ALPHA_COMBINE;
                    break;
            }
        });
        applyPropertyWhenDefined(prop.floatProperties._OutlineWidthMode, (value) => material.outlineWidthMode = value);
        applyPropertyWhenDefined(prop.floatProperties._OutlineColorMode, (value) => material.outlineColorMode = value);
        applyPropertyWhenDefined(prop.floatProperties._CullMode, (value) => material.cullMode = value);
        applyPropertyWhenDefined(prop.floatProperties._OutlineCullMode, (value) => material.outlineCullMode = value);
        applyPropertyWhenDefined(prop.floatProperties._ZWrite, (value) => {
            material.forceDepthWrite = (Math.round(value) === 1);
            if (material.forceDepthWrite) {
                material.disableDepthWrite = false;
            }
        });
    }
}
/**
 * プロパティが設定されていればコールバックを実行する
 */
function applyPropertyWhenDefined(prop, callback) {
    if (typeof prop === 'undefined') {
        return;
    }
    callback(prop);
}
