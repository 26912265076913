/**
 * Collider
 */
export class Collider {
    /**
     * @param offset The local coordinate from the node of the collider group.
     * @param radius The radius of the collider.
     */
    constructor(offset, radius) {
        this.offset = offset;
        this.radius = radius;
    }
}
