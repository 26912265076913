import { SceneComponentConstants } from '@babylonjs/core/sceneComponent';
import { Constants } from '@babylonjs/core/Engines/constants';
const BASE_NAME = 'MToonOutline';
/**
 * MToonMaterial を別のパスで描画するレンダラ
 * TODO: The whole outline rendering has a huge performance cost. Needs a better way.
 * @see OutlineRenderer
 */
export class MToonOutlineRenderer {
    /**
     * @inheritdoc
     * MToonMaterial ごとにインスタンスを生成する
     */
    constructor(scene, material) {
        this.scene = scene;
        this.material = material;
        /**
         * Defines a zOffset default Factor to prevent zFighting between the overlay and the mesh.
         */
        this.zOffset = 1;
        /**
         * Defines a zOffset default Unit to prevent zFighting between the overlay and the mesh.
         */
        this.zOffsetUnits = 4; // 4 to account for projection a bit by default
        this._savedDepthWrite = false;
        this.name = `${BASE_NAME}_${material.name}_${MToonOutlineRenderer.rendererId++}`;
        this.scene._addComponent(this);
        this._engine = this.scene.getEngine();
    }
    /**
     * @inheritdoc
     * シーン描画前後にレンダリング処理を登録する
     */
    register() {
        this.scene._beforeRenderingMeshStage.registerStep(SceneComponentConstants.STEP_BEFORERENDERINGMESH_OUTLINE, this, this._beforeRenderingMesh);
        this.scene._afterRenderingMeshStage.registerStep(SceneComponentConstants.STEP_AFTERRENDERINGMESH_OUTLINE, this, this._afterRenderingMesh);
    }
    /**
     * @inheritdoc
     */
    rebuild() {
        // Nothing to do here.
    }
    /**
     * @inheritdoc
     */
    dispose() {
        // Nothing to do here
    }
    /**
     * アウトラインを描画する
     */
    render(mesh, subMesh, batch, useOverlay = false) {
        const effect = subMesh.effect;
        if (!effect || !effect.isReady() || !this.scene.activeCamera) {
            return;
        }
        const ownerMesh = subMesh.getMesh();
        const replacementMesh = ownerMesh._internalAbstractMeshDataInfo._actAsRegularMesh ? ownerMesh : null;
        const renderingMesh = subMesh.getRenderingMesh();
        const effectiveMesh = replacementMesh ? replacementMesh : renderingMesh;
        // This cullmode change is terrible for performance because it makes
        // materials dirty each frame.
        // const changeCullMode = this.material.cullMode == CullMode.Back;
        // const storedCullMode = this.material.cullMode;
        // if (changeCullMode) {
        //     this.material.cullMode = this.material.outlineCullMode;
        // }
        this._engine.enableEffect(effect);
        renderingMesh._bind(subMesh, effect, this.material.fillMode);
        this._engine.setZOffset(-this.zOffset);
        this._engine.setZOffsetUnits(-this.zOffsetUnits);
        // レンダリング実行
        // for 4.2.0-alpha.0 +
        renderingMesh._processRendering(effectiveMesh, subMesh, effect, this.material.fillMode, batch, this.isHardwareInstancedRendering(subMesh, batch), (isInstance, world, effectiveMaterial) => {
            effectiveMaterial.bindForSubMesh(world, mesh, subMesh);
            effect.setMatrix("world", world);
            effect.setFloat("isOutline", 1.0);
        }, this.material);
        this._engine.setZOffset(0);
        this._engine.setZOffsetUnits(0);
        // if (changeCullMode) {
        //     this.material.cullMode = storedCullMode;
        // }
    }
    /**
     * このメッシュを描画する前に実行されるコールバック
     */
    _beforeRenderingMesh(mesh, subMesh, batch) {
        this._savedDepthWrite = this._engine.getDepthWrite();
        if (!this.willRender(mesh)) {
            return;
        }
        const material = subMesh.getMaterial();
        if (material.needAlphaBlendingForMesh(mesh)) {
            this._engine.cacheStencilState();
            // Draw only to stencil buffer for the original mesh
            // The resulting stencil buffer will be used so the outline is not visible inside the mesh when the mesh is transparent
            this._engine.setDepthWrite(false);
            this._engine.setColorWrite(false);
            this._engine.setStencilBuffer(true);
            this._engine.setStencilOperationPass(Constants.REPLACE);
            this._engine.setStencilFunction(Constants.ALWAYS);
            this._engine.setStencilMask(MToonOutlineRenderer._StencilReference);
            this._engine.setStencilFunctionReference(MToonOutlineRenderer._StencilReference);
            this.render(subMesh.getRenderingMesh(), subMesh, batch, /* This sets offset to 0 */ true);
            this._engine.setColorWrite(true);
            this._engine.setStencilFunction(Constants.NOTEQUAL);
        }
        // 深度ナシで後ろに描画
        this._engine.setDepthWrite(false);
        this.render(subMesh.getRenderingMesh(), subMesh, batch);
        this._engine.setDepthWrite(this._savedDepthWrite);
        if (material.needAlphaBlendingForMesh(mesh)) {
            this._engine.restoreStencilState();
        }
    }
    /**
     * このメッシュを描画した後に実行されるコールバック
     */
    _afterRenderingMesh(mesh, subMesh, batch) {
        if (!this.willRender(mesh)) {
            return;
        }
        if (this._savedDepthWrite) {
            // 深度アリで再度書き込む
            this._engine.setDepthWrite(true);
            this._engine.setColorWrite(false);
            this.render(subMesh.getRenderingMesh(), subMesh, batch);
            this._engine.setColorWrite(true);
        }
    }
    /**
     * インスタンシングを行うかどうか
     */
    isHardwareInstancedRendering(subMesh, batch) {
        if (!this._engine.getCaps().instancedArrays) {
            return false;
        }
        let hasThinInstances = false;
        // from 4.2.0
        hasThinInstances = subMesh.getRenderingMesh().hasThinInstances;
        return (batch.visibleInstances[subMesh._id] !== null)
            && (typeof batch.visibleInstances[subMesh._id] !== 'undefined')
            || hasThinInstances;
    }
    /**
    * このメッシュでアウトラインを描画するかどうか
    */
    willRender(mesh) {
        // @ts-ignore
        const material = mesh._internalMeshDataInfo._effectiveMaterial;
        if (!material || material.getClassName() !== 'MToonMaterial' || material.getOutlineRendererName() !== this.name) {
            // このコンポーネントの Material ではない
            return false;
        }
        return true;
    }
}
/**
 * Stencil value used to avoid outline being seen within the mesh when the mesh is transparent
 */
MToonOutlineRenderer._StencilReference = 0x04;
MToonOutlineRenderer.rendererId = 0;
