import React, {Component} from 'react';
import ReactDOM from 'react-dom';

interface propTypes {
    text: string;
    trim: boolean;
}

class ReactComment extends Component<propTypes> {

    static defaultProps = {
        trim: true
    };

    componentDidMount() {
        let el = ReactDOM.findDOMNode(this) as Element;
        ReactDOM.unmountComponentAtNode(el);
        el.outerHTML = this.createComment();
    }

    createComment() {
        let text = this.props.text as unknown as string;

        if (this.props.trim) {
            text = text.trim();
        }

        return `<!-- ${text} -->`;
    }

    render() {
        return <div />;
    }
}

export default ReactComment;
