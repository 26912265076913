import { GLTFLoader } from '@babylonjs/loaders/glTF/2.0';
import { VRMMaterialGenerator } from './vrm-material-generator';
/**
 * `extensions` に入る拡張キー
 */
const NAME = 'VCAST_vci_material_unity';
/**
 * VCAST_vci_material_unity 拡張を処理する
 */
export class VCAST_vci_material_unity {
    /**
     * @inheritdoc
     */
    constructor(loader) {
        this.loader = loader;
        /**
         * @inheritdoc
         */
        this.name = NAME;
        /**
         * @inheritdoc
         */
        this.enabled = true;
    }
    /**
     * @inheritdoc
     */
    dispose() {
        this.loader = null;
    }
    /**
     * @inheritdoc
     */
    _loadMaterialAsync(context, material, mesh, babylonDrawMode, assign) {
        // ジェネレータでマテリアルを生成する
        if (mesh)
            return (new VRMMaterialGenerator(this.loader)).generate(context, material, mesh, babylonDrawMode, assign);
        else
            return null;
    }
}
// ローダーに登録する
export const registerVciExtension = () => {
    GLTFLoader.RegisterExtension(NAME, (loader) => new VCAST_vci_material_unity(loader));
};
