import Header from "./components/Header";
import MainHero from "./components/MainHero";
import MainHeroImage from "./components/MainHeroImage";
import Canvas from "./components/Canvas";
import LazyShow from "./components/LazyShow";
import Product from "./components/Product";
import Features from "./components/Features";
import About from "./components/About";
import React from "react";

const Home = () => {
    return (
        <div className={`bg-background grid gap-y-16 overflow-hidden`}>
            <div className={`relative bg-background`}>
                <div className="max-w-7xl mx-auto">
                    <div id="header"
                         className={`relative z-10 pb-8 bg-background sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32`}
                    >
                        <Header/>
                        <MainHero/>
                    </div>
                </div>
                <MainHeroImage/>
            </div>
            <LazyShow>
                <>
                    <Canvas/>
                    <Product/>
                </>
            </LazyShow>
            <LazyShow>
                <>
                    <Canvas/>
                    <Features/>
                </>
            </LazyShow>
            {/*<LazyShow>*/}
            {/*    <Pricing/>*/}
            {/*<Canvas/>*/}
            {/*</LazyShow>*/}
            <LazyShow>
                <>
                    <Canvas />
                    <About/>
                </>
            </LazyShow>
        </div>
    );
};

export default Home;

