import { __decorate } from "tslib";
import { serialize, SerializationHelper, serializeAsColor3, expandToProperty, serializeAsTexture } from "@babylonjs/core/Misc/decorators";
import { Scene } from "@babylonjs/core/scene";
import { Matrix, Color3, Vector4 } from "@babylonjs/core/Maths/math";
import { VertexBuffer } from "@babylonjs/core/Buffers/buffer";
import { Material } from "@babylonjs/core/Materials/material";
import { PushMaterial } from "@babylonjs/core/Materials/pushMaterial";
import { MaterialHelper } from "@babylonjs/core/Materials/materialHelper";
import { Constants } from "@babylonjs/core/Engines/constants";
import { EffectFallbacks } from "@babylonjs/core/Materials/effectFallbacks";
import { Effect } from "@babylonjs/core/Materials/effect";
import { DetailMapConfiguration } from "./material.detailMapConfiguration";
import { getInspectableCustomProperties } from "./inspectable-custom-properties";
import { MToonMaterialDefines } from "./mtoon-material-defines";
import { MToonOutlineRenderer } from "./mtoon-outline-renderer";
import { MaterialFlags, RegisterClass } from "@babylonjs/core";
import { bump_fragment_frag } from "./shaders-import/bump-fragment.frag";
import { fragment_declaration_frag } from "./shaders-import/fragment-declaration.frag";
import { light_fragment_frag } from "./shaders-import/light-fragment.frag";
import { light_fragment_precalculation } from "./shaders-import/light-fragment-precalculation.frag";
import { mtoon_frag } from "./shaders-import/mtoon.frag";
import { mtoon_vert } from "./shaders-import/mtoon.vert";
import { ubo_declaration_vert } from "./shaders-import/ubo-declaration.vert";
import { vertex_declaration_vert } from "./shaders-import/vertex-declaration.vert";
// シェーダ文字列を取得
const UboDeclaration = ubo_declaration_vert;
const VertexDeclaration = vertex_declaration_vert;
const FragmentDeclaration = fragment_declaration_frag;
const BumpFragment = bump_fragment_frag;
const LightPreFragment = light_fragment_precalculation;
const LightFragment = light_fragment_frag;
const VertexShader = mtoon_vert;
const FragmentShader = mtoon_frag;
/**
 * デバッグモード
 */
export var DebugMode;
(function (DebugMode) {
    DebugMode[DebugMode["None"] = 0] = "None";
    DebugMode[DebugMode["Normal"] = 1] = "Normal";
    DebugMode[DebugMode["LitShadeRate"] = 2] = "LitShadeRate";
})(DebugMode || (DebugMode = {}));
/**
 * アウトラインカラーモード
 */
export var OutlineColorMode;
(function (OutlineColorMode) {
    OutlineColorMode[OutlineColorMode["FixedColor"] = 0] = "FixedColor";
    OutlineColorMode[OutlineColorMode["MixedLighting"] = 1] = "MixedLighting";
})(OutlineColorMode || (OutlineColorMode = {}));
/**
 * アウトライン幅モード
 */
export var OutlineWidthMode;
(function (OutlineWidthMode) {
    OutlineWidthMode[OutlineWidthMode["None"] = 0] = "None";
    OutlineWidthMode[OutlineWidthMode["WorldCorrdinates"] = 1] = "WorldCorrdinates";
    OutlineWidthMode[OutlineWidthMode["ScreenCoordinates"] = 2] = "ScreenCoordinates";
})(OutlineWidthMode || (OutlineWidthMode = {}));
/**
 * Cull モード
 */
export var CullMode;
(function (CullMode) {
    CullMode[CullMode["Off"] = 0] = "Off";
    CullMode[CullMode["Front"] = 1] = "Front";
    CullMode[CullMode["Back"] = 2] = "Back";
})(CullMode || (CullMode = {}));
const onCreatedEffectParameters = { effect: null, subMesh: null };
/**
 * MToonMaterial
 *
 * MToon は日本のアニメ的表現をすることを目標としています。
 * 主色 (Lit Color) と陰色 (Shade Color) の 2 色を、Lighting パラメータや光源環境に応じて混合することでそれを実現します。
 * VRM での出力パラメータとプロパティのマッピングは下記となります。
 *
 * @link https://github.com/Santarh/MToon/
 * @link https://vrm.dev/univrm/shaders/mtoon/
 */
export class MToonMaterial extends PushMaterial {
    //#endregion
    //#endregion
    /**
     * Instantiates a new MToon material.
     * @see https://vrm.dev/en/docs/univrm/shaders/shader_mtoon/
     * @param name Define the name of the material in the scene
     * @param scene Define the scene the material belong to
     */
    constructor(name, scene) {
        super(name, scene);
        //#region Properties
        //#region Textures
        this._diffuseTexture = null;
        this._ambientTexture = null;
        this._emissiveTexture = null;
        this._specularTexture = null;
        this._bumpTexture = null;
        this._shadeTexture = null;
        this._receiveShadowTexture = null;
        this._shadingGradeTexture = null;
        this._rimTexture = null;
        this._matCapTexture = null;
        this._outlineWidthTexture = null;
        this._uvAnimationMaskTexture = null;
        //#endregion
        //#region Colors
        /**
         * The color of the material lit by the environmental background lighting.
         * @see https://doc.babylonjs.com/babylon101/materials#ambient-color-example
         */
        this.ambientColor = new Color3(0, 0, 0);
        /**
         * The basic color of the material as viewed under a light.
         */
        this.diffuseColor = new Color3(1, 1, 1);
        /**
         * Define how the color and intensity of the highlight given by the light in the material.
         */
        this.specularColor = new Color3(1, 1, 1);
        /**
         * Define the color of the material as if self lit.
         * This will be mixed in the final result even in the absence of light.
         */
        this.emissiveColor = new Color3(0, 0, 0);
        /**
         * shadeTexture に乗算される色
         */
        this.shadeColor = new Color3(0.97, 0.81, 0.86);
        /**
         * Rim の色
         */
        this.rimColor = new Color3(0, 0, 0);
        /**
         * アウトラインの色
         */
        this.outlineColor = new Color3(0, 0, 0);
        //#endregion
        //#region babylon parameters
        /**
         * Defines how sharp are the highlights in the material.
         * The bigger the value the sharper giving a more glossy feeling to the result.
         * Reversely, the smaller the value the blurrier giving a more rough feeling to the result.
         */
        this.specularPower = 64;
        this._useAlphaFromDiffuseTexture = true;
        this._useEmissiveAsIllumination = false;
        this._linkEmissiveWithDiffuse = false;
        this._useSpecularOverAlpha = false;
        this._disableLighting = false;
        this._useObjectSpaceNormalMap = false;
        this._useParallax = false;
        this._useParallaxOcclusion = false;
        /**
         * Defines the alpha limits in alpha test mode.
         */
        this.alphaCutOff = 0.4;
        this._useGlossinessFromSpecularMapAlpha = false;
        this._maxSimultaneousLights = 4;
        this._twoSidedLighting = false;
        /**
         * 頂点カラー非対応
         */
        this.useVertexColor = false;
        /**
         * シェーダボーンは利用可能
         */
        this.useBones = true;
        /**
         * シェーダモーフターゲットは利用可能
         */
        this.useMorphTargets = true;
        /**
         * 頂点アルファは非対応
         */
        this.useVertexAlpha = false;
        /**
         * Defines the detail map parameters for the material.
         */
        this.detailMap = new DetailMapConfiguration(this._markAllSubMeshesAsTexturesDirty.bind(this));
        this._worldViewProjectionMatrix = Matrix.Zero();
        this._globalAmbientColor = new Color3(0, 0, 0);
        //#endregion
        //#region MToon parameters
        this._bumpScale = 0.1;
        /**
         * Apply a scaling factor that determine which "depth" the height map should reprensent. A value between 0.05 and 0.1 is reasonnable in Parallax, you can reach 0.2 using Parallax Occlusion.
         */
        this.parallaxScaleBias = this._bumpScale;
        this._receiveShadowRate = 1;
        this._shadingGradeRate = 1;
        this._shadeShift = 0;
        this._shadeToony = 0.9;
        this._lightColorAttenuation = 0;
        this._indirectLightIntensity = 0.1;
        this._rimLightingMix = 0;
        this._rimFresnelPower = 1;
        this._rimLift = 0;
        this._outlineWidth = 0.5;
        this._outlineScaledMaxDistance = 1;
        this._outlineLightingMix = 1;
        this._uvAnimationScrollX = 0;
        this._uvAnimationScrollY = 0;
        this._uvAnimationRotation = 0;
        this._debugMode = DebugMode.None;
        /** @hidden */
        this.debugMode = DebugMode.None;
        /**
         * MToon Outline Renderer
         * @private
         */
        this.outlineRenderer = new MToonOutlineRenderer(this.getScene(), this);
        this._outlineWidthMode = OutlineWidthMode.None;
        this.outlineWidthMode = OutlineWidthMode.None;
        this._outlineColorMode = OutlineColorMode.MixedLighting;
        this.outlineColorMode = OutlineColorMode.MixedLighting;
        this._cullMode = CullMode.Back;
        this._outlineCullMode = CullMode.Front;
        this.outlineCullMode = CullMode.Front;
        this.storedCullMode = CullMode.Back;
        // シェーダストアに登録する
        if (!Effect.ShadersStore.mtoonVertexShader || !Effect.ShadersStore.mtoonFragmentShader) {
            Effect.IncludesShadersStore.mtoonUboDeclaration = UboDeclaration;
            Effect.IncludesShadersStore.mtoonVertexDeclaration = VertexDeclaration;
            Effect.IncludesShadersStore.mtoonFragmentDeclaration = FragmentDeclaration;
            Effect.IncludesShadersStore.mtoonLightPreFragment = LightPreFragment;
            Effect.IncludesShadersStore.mtoonLightFragment = LightFragment;
            Effect.IncludesShadersStore.mtoonBumpFragment = BumpFragment;
            Effect.ShadersStore.mtoonVertexShader = VertexShader;
            Effect.ShadersStore.mtoonFragmentShader = FragmentShader;
        }
        // Append custom inspectors
        this.inspectableCustomProperties = this.inspectableCustomProperties ?
            this.inspectableCustomProperties.concat(getInspectableCustomProperties())
            : getInspectableCustomProperties();
    }
    /**
     * アクティブなテクスチャ参照の一覧
     */
    appendedActiveTextures() {
        return [
            this._diffuseTexture,
            this._ambientTexture,
            this._emissiveTexture,
            this._specularTexture,
            this._bumpTexture,
            this._shadeTexture,
            this._receiveShadowTexture,
            this._shadingGradeTexture,
            this._rimTexture,
            this._matCapTexture,
            this._outlineWidthTexture,
            this._uvAnimationMaskTexture,
        ].filter((t) => t !== null);
    }
    /**
     * Can this material render to prepass
     */
    get isPrePassCapable() {
        return true;
    }
    get canRenderToMRT() {
        return false;
    }
    get cullMode() {
        return this._cullMode;
    }
    set cullMode(value) {
        this._cullMode = value;
        switch (this._cullMode) {
            case CullMode.Off:
                // 両面を描画する
                this.backFaceCulling = false;
                this.sideOrientation = Material.ClockWiseSideOrientation;
                this.twoSidedLighting = true;
                break;
            case CullMode.Front:
                // 表面を描画しない(=裏面だけ描画する)
                this.backFaceCulling = true;
                this.sideOrientation = Material.CounterClockWiseSideOrientation;
                this.twoSidedLighting = false;
                break;
            case CullMode.Back:
                // 裏面を描画しない(=表面だけ描画する) デフォルト
                this.backFaceCulling = true;
                this.sideOrientation = Material.ClockWiseSideOrientation;
                this.twoSidedLighting = false;
                break;
        }
        this.markAsDirty(Material.TextureDirtyFlag);
    }
    /**
     * アウトライン用 CullMode を設定
     * @hidden
     */
    applyOutlineCullMode() {
        this.storedCullMode = this.cullMode;
        this.cullMode = this._outlineCullMode;
    }
    /**
     * CullMode をリストア
     * @hidden
     */
    restoreOutlineCullMode() {
        this.cullMode = this.storedCullMode;
    }
    /**
     * @hidden
     */
    getOutlineRendererName() {
        return this.outlineRenderer ? this.outlineRenderer.name : "";
    }
    /**
     * Gets the current class name of the material e.g. "StandardMaterial"
     * Mainly use in serialization.
     * @returns the class name
     */
    getClassName() {
        return "MToonMaterial";
    }
    /**
     * In case the depth buffer does not allow enough depth precision for your scene (might be the case in large scenes)
     * You can try switching to logarithmic depth.
     * @see https://doc.babylonjs.com/how_to/using_logarithmic_depth_buffer
     */
    get useLogarithmicDepth() {
        return this._useLogarithmicDepth;
    }
    set useLogarithmicDepth(value) {
        this._useLogarithmicDepth = value && this.getScene().getEngine().getCaps().fragmentDepthSupported;
        this._markAllSubMeshesAsMiscDirty();
    }
    /**
     * Specifies if the material will require alpha blending
     * @returns a boolean specifying if alpha blending is needed
     */
    needAlphaBlending() {
        if (this._disableAlphaBlending) {
            return false;
        }
        return (this.alpha < 1.0) || this._shouldUseAlphaFromDiffuseTexture();
    }
    /**
     * Specifies if this material should be rendered in alpha test mode
     * @returns a boolean specifying if an alpha test is needed.
     */
    needAlphaTesting() {
        if (this._forceAlphaTest) {
            return true;
        }
        return this._hasAlphaChannel() && (this._transparencyMode == null || this._transparencyMode === Material.MATERIAL_ALPHATEST);
    }
    /**
     * Specifies whether or not the alpha value of the diffuse texture should be used for alpha blending.
     */
    _shouldUseAlphaFromDiffuseTexture() {
        return this._diffuseTexture !== null && this._diffuseTexture.hasAlpha && this._useAlphaFromDiffuseTexture && this._transparencyMode !== Material.MATERIAL_OPAQUE;
    }
    /**
     * Specifies whether or not there is a usable alpha channel for transparency.
     */
    _hasAlphaChannel() {
        return (this._diffuseTexture !== null && this._diffuseTexture.hasAlpha);
    }
    /**
     * Get the texture used for alpha test purpose.
     * @returns the diffuse texture in case of the standard material.
     */
    getAlphaTestTexture() {
        return this._diffuseTexture;
    }
    /**
     * Get if the submesh is ready to be used and all its information available.
     * Child classes can use it to update shaders
     * @param mesh defines the mesh to check
     * @param subMesh defines which submesh to check
     * @param useInstances specifies that instances should be used
     * @returns a boolean indicating that the submesh is ready or not
     */
    isReadyForSubMesh(mesh, subMesh, useInstances = false) {
        if (subMesh.effect && this.isFrozen) {
            if (subMesh.effect._wasPreviouslyReady) {
                return true;
            }
        }
        if (!subMesh.materialDefines) {
            subMesh.materialDefines = new MToonMaterialDefines();
        }
        const scene = this.getScene();
        const defines = subMesh.materialDefines;
        if (this._isReadyForSubMesh(subMesh)) {
            return true;
        }
        const engine = scene.getEngine();
        // Lights
        defines._needNormals = MaterialHelper.PrepareDefinesForLights(scene, mesh, defines, true, this._maxSimultaneousLights, this._disableLighting) || (this.outlineWidthMode !== OutlineWidthMode.None);
        // Multiview
        MaterialHelper.PrepareDefinesForMultiview(scene, defines);
        // PrePass
        const oit = this.needAlphaBlendingForMesh(mesh) && this.getScene().useOrderIndependentTransparency;
        MaterialHelper.PrepareDefinesForPrePass(scene, defines, this.canRenderToMRT);
        // Order independant transparency
        MaterialHelper.PrepareDefinesForOIT(scene, defines, oit);
        // Textures
        if (defines._areTexturesDirty) {
            this.applyDefines(defines);
            defines._needUVs = false;
            for (let i = 1; i <= Constants.MAX_SUPPORTED_UV_SETS; ++i) {
                defines["MAINUV" + i] = false;
            }
            if (scene.texturesEnabled) {
                if (this._diffuseTexture && MToonMaterial.DiffuseTextureEnabled) {
                    if (!this._diffuseTexture.isReadyOrNotBlocking()) {
                        return false;
                    }
                    else {
                        MaterialHelper.PrepareDefinesForMergedUV(this._diffuseTexture, defines, "DIFFUSE");
                    }
                }
                else {
                    defines.DIFFUSE = false;
                }
                if (this._ambientTexture && MToonMaterial.AmbientTextureEnabled) {
                    if (!this._ambientTexture.isReadyOrNotBlocking()) {
                        return false;
                    }
                    else {
                        MaterialHelper.PrepareDefinesForMergedUV(this._ambientTexture, defines, "AMBIENT");
                    }
                }
                else {
                    defines.AMBIENT = false;
                }
                if (this._emissiveTexture && MToonMaterial.EmissiveTextureEnabled) {
                    if (!this._emissiveTexture.isReadyOrNotBlocking()) {
                        return false;
                    }
                    else {
                        MaterialHelper.PrepareDefinesForMergedUV(this._emissiveTexture, defines, "EMISSIVE");
                    }
                }
                else {
                    defines.EMISSIVE = false;
                }
                if (this._specularTexture && MToonMaterial.SpecularTextureEnabled) {
                    if (!this._specularTexture.isReadyOrNotBlocking()) {
                        return false;
                    }
                    else {
                        MaterialHelper.PrepareDefinesForMergedUV(this._specularTexture, defines, "SPECULAR");
                        defines.GLOSSINESS = this._useGlossinessFromSpecularMapAlpha;
                    }
                }
                else {
                    defines.SPECULAR = false;
                }
                if (scene.getEngine().getCaps().standardDerivatives && this._bumpTexture) {
                    // Bump texture can not be not blocking.
                    if (!this._bumpTexture.isReady()) {
                        return false;
                    }
                    else {
                        MaterialHelper.PrepareDefinesForMergedUV(this._bumpTexture, defines, "BUMP");
                        defines.PARALLAX = this._useParallax;
                        defines.PARALLAXOCCLUSION = this._useParallaxOcclusion;
                    }
                    defines.OBJECTSPACE_NORMALMAP = this._useObjectSpaceNormalMap;
                }
                else {
                    defines.BUMP = false;
                }
                if (this._shadeTexture) {
                    if (!this._shadeTexture.isReadyOrNotBlocking()) {
                        return false;
                    }
                    else {
                        MaterialHelper.PrepareDefinesForMergedUV(this._shadeTexture, defines, "SHADE");
                    }
                }
                else {
                    defines.SHADE = false;
                }
                if (this._receiveShadowTexture) {
                    if (!this._receiveShadowTexture.isReadyOrNotBlocking()) {
                        return false;
                    }
                    else {
                        MaterialHelper.PrepareDefinesForMergedUV(this._receiveShadowTexture, defines, "RECEIVE_SHADOW");
                    }
                }
                else {
                    defines.RECEIVE_SHADOW = false;
                }
                if (this._shadingGradeTexture) {
                    if (!this._shadingGradeTexture.isReadyOrNotBlocking()) {
                        return false;
                    }
                    else {
                        MaterialHelper.PrepareDefinesForMergedUV(this._shadingGradeTexture, defines, "SHADING_GRADE");
                    }
                }
                else {
                    defines.SHADING_GRADE = false;
                }
                if (this._rimTexture) {
                    if (!this._rimTexture.isReadyOrNotBlocking()) {
                        return false;
                    }
                    else {
                        MaterialHelper.PrepareDefinesForMergedUV(this._rimTexture, defines, "RIM");
                    }
                }
                else {
                    defines.RIM = false;
                }
                if (this._matCapTexture) {
                    if (!this._matCapTexture.isReadyOrNotBlocking()) {
                        return false;
                    }
                    else {
                        MaterialHelper.PrepareDefinesForMergedUV(this._matCapTexture, defines, "MATCAP");
                    }
                }
                else {
                    defines.MATCAP = false;
                }
                if (this._outlineWidthTexture) {
                    if (!this._outlineWidthTexture.isReadyOrNotBlocking()) {
                        return false;
                    }
                    else {
                        MaterialHelper.PrepareDefinesForMergedUV(this._outlineWidthTexture, defines, "OUTLINE_WIDTH");
                    }
                }
                else {
                    defines.OUTLINE_WIDTH = false;
                }
                if (this._uvAnimationMaskTexture) {
                    if (!this._uvAnimationMaskTexture.isReadyOrNotBlocking()) {
                        return false;
                    }
                    else {
                        MaterialHelper.PrepareDefinesForMergedUV(this._uvAnimationMaskTexture, defines, "UV_ANIMATION_MASK");
                    }
                }
                else {
                    defines.UV_ANIMATION_MASK = false;
                }
                defines.TWOSIDEDLIGHTING = !this._backFaceCulling && this._twoSidedLighting;
            }
            else {
                defines.DIFFUSE = false;
                defines.AMBIENT = false;
                defines.EMISSIVE = false;
                defines.BUMP = false;
                defines.SHADE = false;
                defines.RECEIVE_SHADOW = false;
                defines.SHADING_GRADE = false;
                defines.RIM = false;
                defines.MATCAP = false;
                defines.OUTLINE_WIDTH = false;
                defines.UV_ANIMATION_MASK = false;
            }
            defines.ALPHAFROMDIFFUSE = this._shouldUseAlphaFromDiffuseTexture();
            defines.EMISSIVEASILLUMINATION = this._useEmissiveAsIllumination;
            defines.LINKEMISSIVEWITHDIFFUSE = this._linkEmissiveWithDiffuse;
            defines.SPECULAROVERALPHA = this._useSpecularOverAlpha;
            defines.PREMULTIPLYALPHA = (this.alphaMode === Constants.ALPHA_PREMULTIPLIED || this.alphaMode === Constants.ALPHA_PREMULTIPLIED_PORTERDUFF);
            defines.ALPHATEST_AFTERALLALPHACOMPUTATIONS = this.transparencyMode !== null;
            defines.ALPHABLEND = this.transparencyMode === null || this.needAlphaBlendingForMesh(mesh); // check on null for backward compatibility
        }
        if (!this.detailMap.isReadyForSubMesh(defines, scene)) {
            return false;
        }
        // Misc.
        MaterialHelper.PrepareDefinesForMisc(mesh, scene, this._useLogarithmicDepth, this.pointsCloud, this.fogEnabled, this._shouldTurnAlphaTestOn(mesh) || this._forceAlphaTest, defines);
        // Attribs
        MaterialHelper.PrepareDefinesForAttributes(mesh, defines, this.useVertexColor, this.useBones, this.useMorphTargets, this.useVertexAlpha);
        // Values that need to be evaluated on every frame
        MaterialHelper.PrepareDefinesForFrameBoundValues(scene, engine, defines, useInstances, null, subMesh.getRenderingMesh().hasThinInstances);
        // External config
        this.detailMap.prepareDefines(defines, scene);
        // Get correct effect
        if (defines.isDirty) {
            const lightDisposed = defines._areLightsDisposed;
            defines.markAsProcessed();
            // Fallbacks
            const fallbacks = new EffectFallbacks();
            if (defines.SPECULAR) {
                fallbacks.addFallback(0, "SPECULAR");
            }
            if (defines.BUMP) {
                fallbacks.addFallback(0, "BUMP");
            }
            if (defines.PARALLAX) {
                fallbacks.addFallback(1, "PARALLAX");
            }
            if (defines.PARALLAXOCCLUSION) {
                fallbacks.addFallback(0, "PARALLAXOCCLUSION");
            }
            if (defines.SPECULAROVERALPHA) {
                fallbacks.addFallback(0, "SPECULAROVERALPHA");
            }
            if (defines.FOG) {
                fallbacks.addFallback(1, "FOG");
            }
            if (defines.POINTSIZE) {
                fallbacks.addFallback(0, "POINTSIZE");
            }
            if (defines.LOGARITHMICDEPTH) {
                fallbacks.addFallback(0, "LOGARITHMICDEPTH");
            }
            MaterialHelper.HandleFallbacksForShadows(defines, fallbacks, this._maxSimultaneousLights);
            if (defines.SPECULARTERM) {
                fallbacks.addFallback(0, "SPECULARTERM");
            }
            if (defines.MULTIVIEW) {
                fallbacks.addFallback(0, "MULTIVIEW");
            }
            // Attributes
            const attribs = [VertexBuffer.PositionKind];
            if (defines.NORMAL) {
                attribs.push(VertexBuffer.NormalKind);
            }
            if (defines.TANGENT) {
                attribs.push(VertexBuffer.TangentKind);
            }
            for (let i = 1; i <= Constants.MAX_SUPPORTED_UV_SETS; ++i) {
                if (defines["UV" + i]) {
                    attribs.push(`uv${i === 1 ? "" : i}`);
                }
            }
            if (defines.VERTEXCOLOR) {
                attribs.push(VertexBuffer.ColorKind);
            }
            MaterialHelper.PrepareAttributesForBones(attribs, mesh, defines, fallbacks);
            MaterialHelper.PrepareAttributesForInstances(attribs, defines);
            MaterialHelper.PrepareAttributesForMorphTargets(attribs, mesh, defines);
            let shaderName = "mtoon";
            const uniforms = ["world", "view", "viewProjection", "vEyePosition", "vLightsType",
                "vAmbientColor", "vDiffuseColor", "vSpecularColor", "vEmissiveColor", "visibility",
                "vFogInfos", "vFogColor", "pointSize",
                "vDiffuseInfos", "vAmbientInfos", "vEmissiveInfos", "vSpecularInfos", "vBumpInfos",
                "mBones",
                "vClipPlane", "vClipPlane2", "vClipPlane3", "vClipPlane4", "vClipPlane5", "vClipPlane6",
                "diffuseMatrix", "ambientMatrix", "emissiveMatrix", "specularMatrix", "bumpMatrix",
                "logarithmicDepthConstant", "vTangentSpaceParams", "alphaCutOff", "boneTextureWidth",
                "vShadeColor", "vShadeInfos", "shadeMatrix",
                "vReceiveShadowInfos", "receiveShadowMatrix",
                "vShadingGradeInfos", "shadingGradeMatrix",
                "vRimColor", "vRimInfos", "RimMatrix",
                "vMatCapInfos", "MatCapMatrix",
                "vOutlineColor", "vOutlineWidthInfos", "outlineWidthMatrix",
                "aspect", "isOutline",
                "shadingGradeRate", "receiveShadowRate", "shadeShift", "shadeToony",
                "rimLightingMix", "rimFresnelPower", "rimLift",
                "lightColorAttenuation", "indirectLightIntensity",
                "outlineWidth", "outlineScaledMaxDistance", "outlineLightingMix",
                "uvAnimationScrollX", "uvAnimationScrollY", "uvAnimationRotation",
                "vEyeUp", "time",
                "morphTargetTextureInfo", "morphTargetTextureIndices"
            ];
            const samplers = ["diffuseSampler", "ambientSampler", "emissiveSampler",
                "specularSampler", "bumpSampler", "boneSampler",
                "shadeSampler", "receiveShadowSampler", "shadingGradeSampler",
                "rimSampler", "matCapSampler", "outlineWidthSampler",
                "uvAnimationMaskSampler", "morphTargets",
            ];
            const uniformBuffers = ["Material", "Scene"];
            DetailMapConfiguration.AddUniforms(uniforms);
            DetailMapConfiguration.AddSamplers(samplers);
            MaterialHelper.PrepareUniformsAndSamplersList({
                uniformsNames: uniforms,
                uniformBuffersNames: uniformBuffers,
                samplers: samplers,
                defines: defines,
                maxSimultaneousLights: this._maxSimultaneousLights
            });
            const csnrOptions = {};
            if (this.customShaderNameResolve) {
                shaderName = this.customShaderNameResolve(shaderName, uniforms, uniformBuffers, samplers, defines, attribs, csnrOptions);
            }
            const join = defines.toString();
            const previousEffect = subMesh.effect;
            let effect = scene.getEngine().createEffect(shaderName, {
                attributes: attribs,
                uniformsNames: uniforms,
                uniformBuffersNames: uniformBuffers,
                samplers: samplers,
                defines: join,
                fallbacks: fallbacks,
                onCompiled: this.onCompiled,
                onError: this.onError,
                indexParameters: {
                    maxSimultaneousLights: this._maxSimultaneousLights,
                    maxSimultaneousMorphTargets: defines.NUM_MORPH_INFLUENCERS
                },
                processFinalCode: csnrOptions.processFinalCode,
                multiTarget: defines.PREPASS
            }, engine);
            if (effect) {
                if (this._onEffectCreatedObservable) {
                    onCreatedEffectParameters.effect = effect;
                    onCreatedEffectParameters.subMesh = subMesh;
                    this._onEffectCreatedObservable.notifyObservers(onCreatedEffectParameters);
                }
                // Use previous effect while new one is compiling
                if (this.allowShaderHotSwapping && previousEffect && !effect.isReady()) {
                    effect = previousEffect;
                    defines.markAsUnprocessed();
                    if (lightDisposed) {
                        // re register in case it takes more than one frame.
                        defines._areLightsDisposed = true;
                        return false;
                    }
                }
                else {
                    scene.resetCachedMaterial();
                    subMesh.setEffect(effect, defines, this._materialContext);
                    this.buildUniformLayout();
                }
            }
        }
        if (!subMesh.effect || !subMesh.effect.isReady()) {
            return false;
        }
        defines._renderId = scene.getRenderId();
        subMesh.effect._wasPreviouslyReady = true;
        return true;
    }
    /**
     * Builds the material UBO layouts.
     * Used internally during the effect preparation.
     */
    buildUniformLayout() {
        // Order is important !
        const ubo = this._uniformBuffer;
        ubo.addUniform("vDiffuseColor", 4);
        ubo.addUniform("vDiffuseInfos", 2);
        ubo.addUniform("diffuseMatrix", 16);
        ubo.addUniform("vSpecularColor", 4);
        ubo.addUniform("vSpecularInfos", 2);
        ubo.addUniform("specularMatrix", 16);
        ubo.addUniform("vAmbientColor", 3);
        ubo.addUniform("vAmbientInfos", 2);
        ubo.addUniform("ambientMatrix", 16);
        ubo.addUniform("vEmissiveColor", 3);
        ubo.addUniform("vEmissiveInfos", 2);
        ubo.addUniform("emissiveMatrix", 16);
        ubo.addUniform("vBumpInfos", 3);
        ubo.addUniform("bumpMatrix", 16);
        ubo.addUniform("vShadeColor", 3);
        ubo.addUniform("vShadeInfos", 2);
        ubo.addUniform("shadeMatrix", 16);
        ubo.addUniform("vReceiveShadowInfos", 2);
        ubo.addUniform("receiveShadowMatrix", 16);
        ubo.addUniform("vShadingGradeInfos", 2);
        ubo.addUniform("shadingGradeMatrix", 16);
        ubo.addUniform("vRimColor", 3);
        ubo.addUniform("vRimInfos", 2);
        ubo.addUniform("rimMatrix", 16);
        ubo.addUniform("vMatCapInfos", 2);
        ubo.addUniform("matCapMatrix", 16);
        ubo.addUniform("vOutlineColor", 3);
        ubo.addUniform("vOutlineWidthInfos", 2);
        ubo.addUniform("outlineWidthMatrix", 16);
        ubo.addUniform("vUvAnimationMaskInfos", 2);
        ubo.addUniform("uvAnimationMaskMatrix", 16);
        ubo.addUniform("vTangentSpaceParams", 2);
        ubo.addUniform("pointSize", 1);
        ubo.addUniform("alphaCutOff", 1);
        ubo.addUniform("shadingGradeRate", 1);
        ubo.addUniform("receiveShadowRate", 1);
        ubo.addUniform("shadeShift", 1);
        ubo.addUniform("shadeToony", 1);
        ubo.addUniform("lightColorAttenuation", 1);
        ubo.addUniform("indirectLightIntensity", 1);
        ubo.addUniform("rimLightingMix", 1);
        ubo.addUniform("rimFresnelPower", 1);
        ubo.addUniform("rimLift", 1);
        ubo.addUniform("outlineWidth", 1);
        ubo.addUniform("outlineScaledMaxDistance", 1);
        ubo.addUniform("outlineLightingMix", 1);
        ubo.addUniform("uvAnimationScrollX", 1);
        ubo.addUniform("uvAnimationScrollY", 1);
        ubo.addUniform("uvAnimationRotation", 1);
        DetailMapConfiguration.PrepareUniformBuffer(ubo);
        ubo.create();
    }
    /**
     * Unbinds the material from the mesh
     */
    unbind() {
        super.unbind();
    }
    /**
     * Binds the submesh to this material by preparing the effect and shader to draw
     * @param world defines the world transformation matrix
     * @param mesh defines the mesh containing the submesh
     * @param subMesh defines the submesh to bind the material to
     */
    bindForSubMesh(world, mesh, subMesh) {
        const scene = this.getScene();
        const defines = subMesh.materialDefines;
        if (!defines) {
            return;
        }
        const effect = subMesh.effect;
        if (!effect) {
            return;
        }
        this._activeEffect = effect;
        // Matrices Mesh.
        mesh.getMeshUniformBuffer().bindToEffect(effect, "Mesh");
        mesh.transferToEffect(world);
        // Normal Matrix
        if (defines.OBJECTSPACE_NORMALMAP) {
            world.toNormalMatrix(this._normalMatrix);
            this.bindOnlyNormalMatrix(this._normalMatrix);
        }
        const mustRebind = this._mustRebind(scene, effect, mesh.visibility);
        // Bones
        MaterialHelper.BindBonesParameters(mesh, effect);
        const ubo = this._uniformBuffer;
        if (mustRebind) {
            ubo.bindToEffect(effect, "Material");
            this.bindViewProjection(effect);
            if (!ubo.useUbo || !this.isFrozen || !ubo.isSync) {
                // Textures
                if (scene.texturesEnabled) {
                    if (this._diffuseTexture && MToonMaterial.DiffuseTextureEnabled) {
                        ubo.updateFloat2("vDiffuseInfos", this._diffuseTexture.coordinatesIndex, this._diffuseTexture.level);
                        MaterialHelper.BindTextureMatrix(this._diffuseTexture, ubo, "diffuse");
                    }
                    if (this._ambientTexture && MToonMaterial.AmbientTextureEnabled) {
                        ubo.updateFloat2("vAmbientInfos", this._ambientTexture.coordinatesIndex, this._ambientTexture.level);
                        MaterialHelper.BindTextureMatrix(this._ambientTexture, ubo, "ambient");
                    }
                    if (this._hasAlphaChannel()) {
                        ubo.updateFloat("alphaCutOff", this.alphaCutOff);
                    }
                    if (this._emissiveTexture && MToonMaterial.EmissiveTextureEnabled) {
                        ubo.updateFloat2("vEmissiveInfos", this._emissiveTexture.coordinatesIndex, this._emissiveTexture.level);
                        MaterialHelper.BindTextureMatrix(this._emissiveTexture, ubo, "emissive");
                    }
                    if (this._specularTexture && MToonMaterial.SpecularTextureEnabled) {
                        ubo.updateFloat2("vSpecularInfos", this._specularTexture.coordinatesIndex, this._specularTexture.level);
                        MaterialHelper.BindTextureMatrix(this._specularTexture, ubo, "specular");
                    }
                    if (this._bumpTexture && scene.getEngine().getCaps().standardDerivatives && MToonMaterial.BumpTextureEnabled) {
                        ubo.updateFloat3("vBumpInfos", this._bumpTexture.coordinatesIndex, 1.0 / this._bumpTexture.level, this.parallaxScaleBias);
                        MaterialHelper.BindTextureMatrix(this._bumpTexture, ubo, "bump");
                        if (scene._mirroredCameraPosition) {
                            ubo.updateFloat2("vTangentSpaceParams", -1.0, -1.0);
                        }
                        else {
                            ubo.updateFloat2("vTangentSpaceParams", 1.0, 1.0);
                        }
                    }
                    if (this._shadeTexture) {
                        ubo.updateFloat2("vShadeInfos", this._shadeTexture.coordinatesIndex, this._shadeTexture.level);
                        MaterialHelper.BindTextureMatrix(this._shadeTexture, ubo, "shade");
                    }
                    if (this._receiveShadowTexture) {
                        ubo.updateFloat2("vReceiveShadowInfos", this._receiveShadowTexture.coordinatesIndex, this._receiveShadowTexture.level);
                        MaterialHelper.BindTextureMatrix(this._receiveShadowTexture, ubo, "receiveShadow");
                    }
                    if (this._shadingGradeTexture) {
                        ubo.updateFloat2("vShadingGradeInfos", this._shadingGradeTexture.coordinatesIndex, this._shadingGradeTexture.level);
                        MaterialHelper.BindTextureMatrix(this._shadingGradeTexture, ubo, "shadingGrade");
                    }
                    if (this._rimTexture) {
                        ubo.updateFloat2("vRimInfos", this._rimTexture.coordinatesIndex, this._rimTexture.level);
                        MaterialHelper.BindTextureMatrix(this._rimTexture, ubo, "rim");
                    }
                    if (this._matCapTexture) {
                        ubo.updateFloat2("vMatCapInfos", this._matCapTexture.coordinatesIndex, this._matCapTexture.level);
                        MaterialHelper.BindTextureMatrix(this._matCapTexture, ubo, "matCap");
                    }
                    if (this._outlineWidthTexture) {
                        ubo.updateFloat2("vOutlineWidthInfos", this._outlineWidthTexture.coordinatesIndex, this._outlineWidthTexture.level);
                        MaterialHelper.BindTextureMatrix(this._outlineWidthTexture, ubo, "outlineWidth");
                    }
                    if (this._uvAnimationMaskTexture) {
                        ubo.updateFloat2("vUvAnimationMaskInfos", this._uvAnimationMaskTexture.coordinatesIndex, this._uvAnimationMaskTexture.level);
                        MaterialHelper.BindTextureMatrix(this._uvAnimationMaskTexture, ubo, "uvAnimationMask");
                    }
                }
            }
            // Point size
            if (this.pointsCloud) {
                ubo.updateFloat("pointSize", this.pointSize);
            }
            if (defines.SPECULARTERM) {
                ubo.updateColor4("vSpecularColor", this.specularColor, this.specularPower);
            }
            ubo.updateColor3("vEmissiveColor", this.emissiveColor);
            ubo.updateColor4("vDiffuseColor", this.diffuseColor, this.alpha);
            scene.ambientColor.multiplyToRef(this.ambientColor, this._globalAmbientColor);
            ubo.updateColor3("vAmbientColor", this._globalAmbientColor);
            // MToon uniforms
            ubo.updateFloat("receiveShadowRate", this._receiveShadowRate);
            ubo.updateFloat("shadingGradeRate", this._shadingGradeRate);
            ubo.updateFloat("shadeShift", this._shadeShift);
            ubo.updateFloat("shadeToony", this._shadeToony);
            ubo.updateFloat("lightColorAttenuation", this._lightColorAttenuation);
            ubo.updateFloat("indirectLightIntensity", this._indirectLightIntensity);
            ubo.updateFloat("rimLightingMix", this._rimLightingMix);
            ubo.updateFloat("rimFresnelPower", this._rimFresnelPower);
            ubo.updateFloat("rimLift", this._rimLift);
            ubo.updateFloat("outlineWidth", this._outlineWidth);
            ubo.updateFloat("outlineScaledMaxDistance", this._outlineScaledMaxDistance);
            ubo.updateFloat("outlineLightingMix", this._outlineLightingMix);
            ubo.updateFloat("uvAnimationScrollX", this._uvAnimationScrollX);
            ubo.updateFloat("uvAnimationScrollY", this._uvAnimationScrollY);
            ubo.updateFloat("uvAnimationRotation", this._uvAnimationRotation);
            // Textures
            if (scene.texturesEnabled) {
                if (this._diffuseTexture && MToonMaterial.DiffuseTextureEnabled) {
                    effect.setTexture("diffuseSampler", this._diffuseTexture);
                }
                if (this._ambientTexture && MToonMaterial.AmbientTextureEnabled) {
                    effect.setTexture("ambientSampler", this._ambientTexture);
                }
                if (this._emissiveTexture && MToonMaterial.EmissiveTextureEnabled) {
                    effect.setTexture("emissiveSampler", this._emissiveTexture);
                }
                if (this._specularTexture && MToonMaterial.SpecularTextureEnabled) {
                    effect.setTexture("specularSampler", this._specularTexture);
                }
                if (this._bumpTexture && scene.getEngine().getCaps().standardDerivatives && MToonMaterial.BumpTextureEnabled) {
                    effect.setTexture("bumpSampler", this._bumpTexture);
                }
                if (this._shadeTexture) {
                    effect.setTexture("shadeSampler", this._shadeTexture);
                }
                if (this._receiveShadowTexture) {
                    effect.setTexture("receiveShadowSampler", this._receiveShadowTexture);
                }
                if (this._shadingGradeTexture) {
                    effect.setTexture("shadingGradeSampler", this._shadingGradeTexture);
                }
                if (this._rimTexture) {
                    effect.setTexture("rimSampler", this._rimTexture);
                }
                if (this._matCapTexture) {
                    effect.setTexture("matCapSampler", this._matCapTexture);
                }
                if (this._outlineWidthTexture) {
                    effect.setTexture("outlineWidthSampler", this._outlineWidthTexture);
                }
                if (this._uvAnimationMaskTexture) {
                    effect.setTexture("uvAnimationMaskSampler", this._uvAnimationMaskTexture);
                }
            }
            // OIT with depth peeling
            if (this.getScene().useOrderIndependentTransparency && this.needAlphaBlendingForMesh(mesh)) {
                this.getScene().depthPeelingRenderer.bind(effect);
            }
            this.detailMap.bindForSubMesh(ubo, scene, this.isFrozen);
            // Clip plane
            MaterialHelper.BindClipPlane(effect, scene);
            // Colors
            this.bindEyePosition(effect);
            effect.setVector3("vEyeUp", scene.activeCamera.upVector);
            ubo.updateColor3("vShadeColor", this.shadeColor);
            ubo.updateColor3("vRimColor", this.rimColor);
            ubo.updateColor4("vOutlineColor", this.outlineColor, 1.0);
        }
        if (mustRebind || !this.isFrozen) {
            // Lights
            if (scene.lightsEnabled && !this._disableLighting) {
                MaterialHelper.BindLights(scene, mesh, effect, defines, this._maxSimultaneousLights);
            }
            // View
            if (scene.fogEnabled && mesh.applyFog && scene.fogMode !== Scene.FOGMODE_NONE || mesh.receiveShadows) {
                this.bindView(effect);
            }
            // Fog
            MaterialHelper.BindFogParameters(scene, mesh, effect);
            // Morph targets
            if (defines.NUM_MORPH_INFLUENCERS) {
                MaterialHelper.BindMorphTargetParameters(mesh, effect);
            }
            // Log. depth
            if (this.useLogarithmicDepth) {
                MaterialHelper.BindLogDepth(defines, effect, scene);
            }
        }
        effect.setFloat("aspect", scene.getEngine().getAspectRatio(scene.activeCamera));
        effect.setFloat("isOutline", 0);
        const t = window.performance.now() / 1000;
        effect.setVector4("time", new Vector4(t / 20, t, t * 2, t * 3));
        this._afterBind(mesh, this._activeEffect);
        ubo.update();
    }
    /**
     * Get the list of animatables in the material.
     * @returns the list of animatables object used in the material
     */
    getAnimatables() {
        const results = [];
        for (const texture of this.appendedActiveTextures()) {
            if (texture.animations && texture.animations.length > 0) {
                results.push(texture);
            }
        }
        this.detailMap.getAnimatables(results);
        return results;
    }
    /**
     * Gets the active textures from the material
     * @returns an array of textures
     */
    getActiveTextures() {
        const activeTextures = super.getActiveTextures().concat(this.appendedActiveTextures());
        this.detailMap.getActiveTextures(activeTextures);
        return activeTextures;
    }
    /**
     * Specifies if the material uses a texture
     * @param texture defines the texture to check against the material
     * @returns a boolean specifying if the material uses the texture
     */
    hasTexture(texture) {
        if (super.hasTexture(texture)) {
            return true;
        }
        const activeTextures = this.appendedActiveTextures();
        return activeTextures.length > 0 ?
            activeTextures.some((e) => e === texture)
            : this.detailMap.hasTexture(texture);
    }
    /**
     * Disposes the material
     * @param forceDisposeEffect specifies if effects should be forcefully disposed
     * @param forceDisposeTextures specifies if textures should be forcefully disposed
     */
    dispose(forceDisposeEffect, forceDisposeTextures) {
        if (forceDisposeTextures) {
            this.appendedActiveTextures().forEach((e) => e.dispose());
        }
        this.detailMap.dispose(forceDisposeTextures);
        super.dispose(forceDisposeEffect, forceDisposeTextures);
    }
    /**
     * 定数を設定する
     */
    applyDefines(defines) {
        switch (this._debugMode) {
            case DebugMode.Normal:
                if (defines.MTOON_DEBUG_NORMAL !== true) {
                    defines.MTOON_DEBUG_NORMAL = true;
                    defines.MTOON_DEBUG_LITSHADERATE = false;
                    defines.markAsUnprocessed();
                }
                break;
            case DebugMode.LitShadeRate:
                if (defines.MTOON_DEBUG_LITSHADERATE !== true) {
                    defines.MTOON_DEBUG_NORMAL = false;
                    defines.MTOON_DEBUG_LITSHADERATE = true;
                    defines.markAsUnprocessed();
                }
                break;
            case DebugMode.None:
                if (defines.MTOON_DEBUG_NORMAL === true) {
                    defines.MTOON_DEBUG_NORMAL = false;
                    defines.markAsUnprocessed();
                }
                if (defines.MTOON_DEBUG_LITSHADERATE === true) {
                    defines.MTOON_DEBUG_LITSHADERATE = false;
                    defines.markAsUnprocessed();
                }
                break;
        }
        switch (this._outlineWidthMode) {
            case OutlineWidthMode.WorldCorrdinates:
                if (defines.MTOON_OUTLINE_WIDTH_WORLD !== true) {
                    defines.MTOON_OUTLINE_WIDTH_WORLD = true;
                    defines.MTOON_OUTLINE_WIDTH_SCREEN = false;
                    defines.markAsUnprocessed();
                }
                break;
            case OutlineWidthMode.ScreenCoordinates:
                if (defines.MTOON_OUTLINE_WIDTH_SCREEN !== true) {
                    defines.MTOON_OUTLINE_WIDTH_WORLD = false;
                    defines.MTOON_OUTLINE_WIDTH_SCREEN = true;
                    defines.markAsUnprocessed();
                }
                break;
            case OutlineWidthMode.None:
                if (defines.MTOON_OUTLINE_WIDTH_SCREEN !== false || defines.MTOON_OUTLINE_WIDTH_WORLD !== false) {
                    defines.MTOON_OUTLINE_WIDTH_SCREEN = false;
                    defines.MTOON_OUTLINE_WIDTH_WORLD = false;
                    defines.markAsUnprocessed();
                }
                break;
        }
        switch (this._outlineColorMode) {
            case OutlineColorMode.FixedColor:
                if (defines.MTOON_OUTLINE_COLOR_FIXED !== true) {
                    defines.MTOON_OUTLINE_COLOR_FIXED = true;
                    defines.MTOON_OUTLINE_COLOR_MIXED = false;
                    defines.markAsUnprocessed();
                }
                break;
            case OutlineColorMode.MixedLighting:
                if (defines.MTOON_OUTLINE_COLOR_MIXED !== true) {
                    defines.MTOON_OUTLINE_COLOR_FIXED = false;
                    defines.MTOON_OUTLINE_COLOR_MIXED = true;
                    defines.markAsUnprocessed();
                }
                break;
        }
    }
    //#region Misc
    /**
     * Makes a duplicate of the material, and gives it a new name
     * @param name defines the new name for the duplicated material
     * @returns the cloned material
     */
    clone(name) {
        const result = SerializationHelper.Clone(() => new MToonMaterial(name, this.getScene()), this);
        result.name = name;
        result.id = name;
        this.stencil.copyTo(result.stencil);
        return result;
    }
    /**
     * Serializes this material in a JSON representation
     * @returns the serialized material object
     */
    serialize() {
        const serializationObject = SerializationHelper.Serialize(this);
        serializationObject.stencil = this.stencil.serialize();
        return serializationObject;
    }
    /**
     * Creates a standard material from parsed material data
     * @param source defines the JSON representation of the material
     * @param scene defines the hosting scene
     * @param rootUrl defines the root URL to use to load textures and relative dependencies
     * @returns a new standard material
     */
    static Parse(source, scene, rootUrl) {
        const material = SerializationHelper.Parse(() => new MToonMaterial(source.name, scene), source, scene, rootUrl);
        if (source.stencil) {
            material.stencil.parse(source.stencil, scene, rootUrl);
        }
        return material;
    }
    //#endregion
    // Flags used to enable or disable a type of texture for all Standard Materials
    /**
     * Are diffuse textures enabled in the application.
     */
    static get DiffuseTextureEnabled() {
        return MaterialFlags.DiffuseTextureEnabled;
    }
    static set DiffuseTextureEnabled(value) {
        MaterialFlags.DiffuseTextureEnabled = value;
    }
    /**
     * Are ambient textures enabled in the application.
     */
    static get AmbientTextureEnabled() {
        return MaterialFlags.AmbientTextureEnabled;
    }
    static set AmbientTextureEnabled(value) {
        MaterialFlags.AmbientTextureEnabled = value;
    }
    /**
     * Are emissive textures enabled in the application.
     */
    static get EmissiveTextureEnabled() {
        return MaterialFlags.EmissiveTextureEnabled;
    }
    static set EmissiveTextureEnabled(value) {
        MaterialFlags.EmissiveTextureEnabled = value;
    }
    /**
     * Are specular textures enabled in the application.
     */
    static get SpecularTextureEnabled() {
        return MaterialFlags.SpecularTextureEnabled;
    }
    static set SpecularTextureEnabled(value) {
        MaterialFlags.SpecularTextureEnabled = value;
    }
    /**
     * Are bump textures enabled in the application.
     */
    static get BumpTextureEnabled() {
        return MaterialFlags.BumpTextureEnabled;
    }
    static set BumpTextureEnabled(value) {
        MaterialFlags.BumpTextureEnabled = value;
    }
}
__decorate([
    serializeAsTexture("diffuseTexture")
], MToonMaterial.prototype, "_diffuseTexture", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesAndMiscDirty")
], MToonMaterial.prototype, "diffuseTexture", void 0);
__decorate([
    serializeAsTexture("ambientTexture")
], MToonMaterial.prototype, "_ambientTexture", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "ambientTexture", void 0);
__decorate([
    serializeAsTexture("emissiveTexture")
], MToonMaterial.prototype, "_emissiveTexture", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "emissiveTexture", void 0);
__decorate([
    serializeAsTexture("specularTexture")
], MToonMaterial.prototype, "_specularTexture", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "specularTexture", void 0);
__decorate([
    serializeAsTexture("bumpTexture")
], MToonMaterial.prototype, "_bumpTexture", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "bumpTexture", void 0);
__decorate([
    serializeAsTexture("shadeTexture")
], MToonMaterial.prototype, "_shadeTexture", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "shadeTexture", void 0);
__decorate([
    serializeAsTexture("receiveShadowTexture")
], MToonMaterial.prototype, "_receiveShadowTexture", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "receiveShadowTexture", void 0);
__decorate([
    serializeAsTexture("shadingGradeTexture")
], MToonMaterial.prototype, "_shadingGradeTexture", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "shadingGradeTexture", void 0);
__decorate([
    serializeAsTexture("rimTexture")
], MToonMaterial.prototype, "_rimTexture", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "rimTexture", void 0);
__decorate([
    serializeAsTexture("matCapTexture")
], MToonMaterial.prototype, "_matCapTexture", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "matCapTexture", void 0);
__decorate([
    serializeAsTexture("outlineWidthTexture")
], MToonMaterial.prototype, "_outlineWidthTexture", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "outlineWidthTexture", void 0);
__decorate([
    serializeAsTexture("uvAnimationMaskTexture")
], MToonMaterial.prototype, "_uvAnimationMaskTexture", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "uvAnimationMaskTexture", void 0);
__decorate([
    serializeAsColor3("ambient")
], MToonMaterial.prototype, "ambientColor", void 0);
__decorate([
    serializeAsColor3("diffuse")
], MToonMaterial.prototype, "diffuseColor", void 0);
__decorate([
    serializeAsColor3("specular")
], MToonMaterial.prototype, "specularColor", void 0);
__decorate([
    serializeAsColor3("emissive")
], MToonMaterial.prototype, "emissiveColor", void 0);
__decorate([
    serializeAsColor3("shade")
], MToonMaterial.prototype, "shadeColor", void 0);
__decorate([
    serializeAsColor3("rim")
], MToonMaterial.prototype, "rimColor", void 0);
__decorate([
    serializeAsColor3("outline")
], MToonMaterial.prototype, "outlineColor", void 0);
__decorate([
    serialize()
], MToonMaterial.prototype, "specularPower", void 0);
__decorate([
    serialize("useAlphaFromDiffuseTexture")
], MToonMaterial.prototype, "_useAlphaFromDiffuseTexture", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesAndMiscDirty")
], MToonMaterial.prototype, "useAlphaFromDiffuseTexture", void 0);
__decorate([
    serialize("useEmissiveAsIllumination")
], MToonMaterial.prototype, "_useEmissiveAsIllumination", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "useEmissiveAsIllumination", void 0);
__decorate([
    serialize("linkEmissiveWithDiffuse")
], MToonMaterial.prototype, "_linkEmissiveWithDiffuse", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "linkEmissiveWithDiffuse", void 0);
__decorate([
    serialize("useSpecularOverAlpha")
], MToonMaterial.prototype, "_useSpecularOverAlpha", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "useSpecularOverAlpha", void 0);
__decorate([
    serialize("disableLighting")
], MToonMaterial.prototype, "_disableLighting", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsLightsDirty")
], MToonMaterial.prototype, "disableLighting", void 0);
__decorate([
    serialize("useObjectSpaceNormalMap")
], MToonMaterial.prototype, "_useObjectSpaceNormalMap", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "useObjectSpaceNormalMap", void 0);
__decorate([
    serialize("useParallax")
], MToonMaterial.prototype, "_useParallax", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "useParallax", void 0);
__decorate([
    serialize("useParallaxOcclusion")
], MToonMaterial.prototype, "_useParallaxOcclusion", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "useParallaxOcclusion", void 0);
__decorate([
    serialize()
], MToonMaterial.prototype, "alphaCutOff", void 0);
__decorate([
    serialize("useGlossinessFromSpecularMapAlpha")
], MToonMaterial.prototype, "_useGlossinessFromSpecularMapAlpha", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "useGlossinessFromSpecularMapAlpha", void 0);
__decorate([
    serialize("maxSimultaneousLights")
], MToonMaterial.prototype, "_maxSimultaneousLights", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsLightsDirty")
], MToonMaterial.prototype, "maxSimultaneousLights", void 0);
__decorate([
    serialize("twoSidedLighting")
], MToonMaterial.prototype, "_twoSidedLighting", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], MToonMaterial.prototype, "twoSidedLighting", void 0);
__decorate([
    serialize("bumpScale")
], MToonMaterial.prototype, "_bumpScale", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsTexturesAndMiscDirty")
], MToonMaterial.prototype, "bumpScale", void 0);
__decorate([
    serialize()
], MToonMaterial.prototype, "parallaxScaleBias", void 0);
__decorate([
    serialize("receiveShadowRate")
], MToonMaterial.prototype, "_receiveShadowRate", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsLightsDirty")
], MToonMaterial.prototype, "receiveShadowRate", void 0);
__decorate([
    serialize("shadingGradeRate")
], MToonMaterial.prototype, "_shadingGradeRate", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsLightsDirty")
], MToonMaterial.prototype, "shadingGradeRate", void 0);
__decorate([
    serialize("shadeShift")
], MToonMaterial.prototype, "_shadeShift", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsLightsDirty")
], MToonMaterial.prototype, "shadeShift", void 0);
__decorate([
    serialize("shadeToony")
], MToonMaterial.prototype, "_shadeToony", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsLightsDirty")
], MToonMaterial.prototype, "shadeToony", void 0);
__decorate([
    serialize("lightColorAttenuation")
], MToonMaterial.prototype, "_lightColorAttenuation", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsLightsDirty")
], MToonMaterial.prototype, "lightColorAttenuation", void 0);
__decorate([
    serialize("indirectLightIntensity")
], MToonMaterial.prototype, "_indirectLightIntensity", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsLightsDirty")
], MToonMaterial.prototype, "indirectLightIntensity", void 0);
__decorate([
    serialize("rimLightingMix")
], MToonMaterial.prototype, "_rimLightingMix", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsLightsDirty")
], MToonMaterial.prototype, "rimLightingMix", void 0);
__decorate([
    serialize("rimFresnelPower")
], MToonMaterial.prototype, "_rimFresnelPower", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsLightsDirty")
], MToonMaterial.prototype, "rimFresnelPower", void 0);
__decorate([
    serialize("rimLift")
], MToonMaterial.prototype, "_rimLift", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsLightsDirty")
], MToonMaterial.prototype, "rimLift", void 0);
__decorate([
    serialize("outlineWidth")
], MToonMaterial.prototype, "_outlineWidth", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsAttributesDirty")
], MToonMaterial.prototype, "outlineWidth", void 0);
__decorate([
    serialize("outlineScaledMaxDistance")
], MToonMaterial.prototype, "_outlineScaledMaxDistance", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsAttributesDirty")
], MToonMaterial.prototype, "outlineScaledMaxDistance", void 0);
__decorate([
    serialize("outlineLightingMix")
], MToonMaterial.prototype, "_outlineLightingMix", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsAttributesDirty")
], MToonMaterial.prototype, "outlineLightingMix", void 0);
__decorate([
    serialize("uvAnimationScrollX")
], MToonMaterial.prototype, "_uvAnimationScrollX", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsMiscDirty")
], MToonMaterial.prototype, "uvAnimationScrollX", void 0);
__decorate([
    serialize("uvAnimationScrollY")
], MToonMaterial.prototype, "_uvAnimationScrollY", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsMiscDirty")
], MToonMaterial.prototype, "uvAnimationScrollY", void 0);
__decorate([
    serialize("uvAnimationRotation")
], MToonMaterial.prototype, "_uvAnimationRotation", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsMiscDirty")
], MToonMaterial.prototype, "uvAnimationRotation", void 0);
__decorate([
    serialize("debugMode")
], MToonMaterial.prototype, "_debugMode", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsMiscDirty")
], MToonMaterial.prototype, "debugMode", void 0);
__decorate([
    serialize("outlineWidthMode")
], MToonMaterial.prototype, "_outlineWidthMode", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsMiscDirty")
], MToonMaterial.prototype, "outlineWidthMode", void 0);
__decorate([
    serialize("outlineColorMode")
], MToonMaterial.prototype, "_outlineColorMode", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsMiscDirty")
], MToonMaterial.prototype, "outlineColorMode", void 0);
__decorate([
    serialize("cullMode")
], MToonMaterial.prototype, "_cullMode", void 0);
__decorate([
    serialize("outlineCullMode")
], MToonMaterial.prototype, "_outlineCullMode", void 0);
__decorate([
    expandToProperty("_markAllSubMeshesAsMiscDirty")
], MToonMaterial.prototype, "outlineCullMode", void 0);
__decorate([
    serialize()
], MToonMaterial.prototype, "useLogarithmicDepth", null);
RegisterClass("BABYLON.MToonMaterial", MToonMaterial);
