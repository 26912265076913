import { Vector3 } from '@babylonjs/core/Maths/math';
import { ColliderGroup } from './collider-group';
import { VRMSpringBone } from './vrm-spring-bone';
/**
 * VRM SpringBone Controller
 */
export class SpringBoneController {
    /**
     * @param ext SecondaryAnimation Object
     * @param getBone
     * @param options Override for constructSprings
     */
    constructor(ext, getBone, options) {
        this.ext = ext;
        const colliderGroups = this.constructColliderGroups(getBone);
        this.springs = this.constructSprings(getBone, colliderGroups, options);
    }
    dispose() {
        this.springs = [];
    }
    /**
     * Initialize SpringBones
     */
    setup(force = false) {
        this.springs.forEach((spring) => {
            spring.setup(force);
        });
    }
    /**
     * Update all SpringBones
     *
     * @param deltaTime Elapsed sec from previous frame
     * @param boneOptions options for updating bones
     * @see https://docs.unity3d.com/ScriptReference/Time-deltaTime.html
     */
    async update(deltaTime, boneOptions) {
        // ポーズ後のあらぶり防止のため clamp
        deltaTime = Math.max(0.0, Math.min(125, deltaTime)) / 1000;
        const promises = this.springs.map((spring) => {
            return spring.update(deltaTime, boneOptions);
        });
        return Promise.all(promises).then(() => { });
    }
    constructColliderGroups(getBone) {
        if (!this.ext.colliderGroups || !this.ext.colliderGroups.length) {
            return [];
        }
        const colliderGroups = [];
        this.ext.colliderGroups.forEach((colliderGroup) => {
            const bone = getBone(colliderGroup.node);
            const g = new ColliderGroup(bone);
            colliderGroup.colliders.forEach((collider) => {
                g.addCollider(
                // Unity 座標系からの変換のため X, Z 軸を反転
                new Vector3(-collider.offset.x, collider.offset.y, -collider.offset.z), collider.radius);
            });
            colliderGroups.push(g);
        });
        return colliderGroups;
    }
    constructSprings(getBone, colliderGroups, options) {
        if (!this.ext.boneGroups || !this.ext.boneGroups.length) {
            return [];
        }
        const springs = [];
        this.ext.boneGroups.forEach((spring) => {
            const rootBones = (spring.bones || []).map((bone) => {
                return getBone(bone);
            });
            const springColliders = (spring.colliderGroups || []).map((g) => {
                return colliderGroups[g];
            });
            springs.push(new VRMSpringBone(spring.comment, options?.stiffness
                ? options.stiffness
                : spring.stiffiness, options?.gravityPower
                ? options.gravityPower
                : spring.gravityPower, options?.gravityDir
                ? options.gravityDir
                : new Vector3(
                // Unity 座標系からの変換のため X, Z 軸を反転
                -spring.gravityDir.x, spring.gravityDir.y, -spring.gravityDir.z).normalize(), options?.dragForce
                ? options.dragForce
                : spring.dragForce, getBone(spring.center), options?.hitRadius
                ? options.hitRadius
                : spring.hitRadius, rootBones, springColliders));
        });
        return springs;
    }
}
