/**
 * Runtime Sphere Collider
 */
export class SphereCollider {
    /**
     * @param position Absolute Collider Position
     * @param radius Collider radius
     */
    constructor(position, radius) {
        this.position = position;
        this.radius = radius;
    }
}
