import { Collider } from './collider';
/**
 * VRM SpringBone ColliderGroup
 */
export class ColliderGroup {
    /**
     * @param transform The node of the collider group for setting up collision detections.
     */
    constructor(transform) {
        this.transform = transform;
        this.colliders = [];
    }
    /**
     * Add offsetted collider
     *
     * @param offset The local coordinate from the node of the collider group.
     * @param radius The radius of the collider.
     */
    addCollider(offset, radius) {
        this.colliders.push(new Collider(offset, radius));
    }
}
