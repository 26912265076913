import { __decorate } from "tslib";
import { serializeAsTexture, expandToProperty, serialize, Material, MaterialFlags, MaterialHelper, SerializationHelper, } from "@babylonjs/core";
/**
 * Define the code related to the detail map parameters of a material
 *
 * Inspired from:
 *   Unity: https://docs.unity3d.com/Packages/com.unity.render-pipelines.high-definition@9.0/manual/Mask-Map-and-Detail-Map.html and https://docs.unity3d.com/Manual/StandardShaderMaterialParameterDetail.html
 *   Unreal: https://docs.unrealengine.com/en-US/Engine/Rendering/Materials/HowTo/DetailTexturing/index.html
 *   Cryengine: https://docs.cryengine.com/display/SDKDOC2/Detail+Maps
 */
export class DetailMapConfiguration {
    /**
     * Instantiate a new detail map
     * @param markAllSubMeshesAsTexturesDirty Callback to flag the material to dirty
     */
    constructor(markAllSubMeshesAsTexturesDirty) {
        this._texture = null;
        /**
         * Defines how strongly the detail diffuse/albedo channel is blended with the regular diffuse/albedo texture
         * Bigger values mean stronger blending
         */
        this.diffuseBlendLevel = 1;
        /**
         * Defines how strongly the detail roughness channel is blended with the regular roughness value
         * Bigger values mean stronger blending. Only used with PBR materials
         */
        this.roughnessBlendLevel = 1;
        /**
         * Defines how strong the bump effect from the detail map is
         * Bigger values mean stronger effect
         */
        this.bumpLevel = 1;
        this._normalBlendMethod = Material.MATERIAL_NORMALBLENDMETHOD_WHITEOUT;
        this._isEnabled = false;
        /**
         * Enable or disable the detail map on this material
         */
        this.isEnabled = false;
        this._internalMarkAllSubMeshesAsTexturesDirty = markAllSubMeshesAsTexturesDirty;
    }
    /** @hidden */
    _markAllSubMeshesAsTexturesDirty() {
        this._internalMarkAllSubMeshesAsTexturesDirty();
    }
    /**
     * Gets whether the submesh is ready to be used or not.
     * @param defines the list of "defines" to update.
     * @param scene defines the scene the material belongs to.
     * @returns - boolean indicating that the submesh is ready or not.
     */
    isReadyForSubMesh(defines, scene) {
        if (!this._isEnabled) {
            return true;
        }
        const engine = scene.getEngine();
        if (defines._areTexturesDirty && scene.texturesEnabled) {
            if (engine.getCaps().standardDerivatives && this._texture && MaterialFlags.DetailTextureEnabled) {
                // Detail texture cannot be not blocking.
                if (!this._texture.isReady()) {
                    return false;
                }
            }
        }
        return true;
    }
    /**
     * Update the defines for detail map usage
     * @param defines the list of "defines" to update.
     * @param scene defines the scene the material belongs to.
     */
    prepareDefines(defines, scene) {
        if (this._isEnabled) {
            defines.DETAIL_NORMALBLENDMETHOD = this._normalBlendMethod;
            const engine = scene.getEngine();
            if (defines._areTexturesDirty) {
                if (engine.getCaps().standardDerivatives && this._texture && MaterialFlags.DetailTextureEnabled && this._isEnabled) {
                    MaterialHelper.PrepareDefinesForMergedUV(this._texture, defines, "DETAIL");
                    defines.DETAIL_NORMALBLENDMETHOD = this._normalBlendMethod;
                }
                else {
                    defines.DETAIL = false;
                }
            }
        }
        else {
            defines.DETAIL = false;
        }
    }
    /**
     * Binds the material data.
     * @param uniformBuffer defines the Uniform buffer to fill in.
     * @param scene defines the scene the material belongs to.
     * @param isFrozen defines whether the material is frozen or not.
     */
    bindForSubMesh(uniformBuffer, scene, isFrozen) {
        if (!this._isEnabled) {
            return;
        }
        if (!uniformBuffer.useUbo || !isFrozen || !uniformBuffer.isSync) {
            if (this._texture && MaterialFlags.DetailTextureEnabled) {
                uniformBuffer.updateFloat4("vDetailInfos", this._texture.coordinatesIndex, this.diffuseBlendLevel, this.bumpLevel, this.roughnessBlendLevel);
                MaterialHelper.BindTextureMatrix(this._texture, uniformBuffer, "detail");
            }
        }
        // Textures
        if (scene.texturesEnabled) {
            if (this._texture && MaterialFlags.DetailTextureEnabled) {
                uniformBuffer.setTexture("detailSampler", this._texture);
            }
        }
    }
    /**
     * Checks to see if a texture is used in the material.
     * @param texture - Base texture to use.
     * @returns - Boolean specifying if a texture is used in the material.
     */
    hasTexture(texture) {
        if (this._texture === texture) {
            return true;
        }
        return false;
    }
    /**
     * Returns an array of the actively used textures.
     * @param activeTextures Array of BaseTextures
     */
    getActiveTextures(activeTextures) {
        if (this._texture) {
            activeTextures.push(this._texture);
        }
    }
    /**
     * Returns the animatable textures.
     * @param animatables Array of animatable textures.
     */
    getAnimatables(animatables) {
        if (this._texture && this._texture.animations && this._texture.animations.length > 0) {
            animatables.push(this._texture);
        }
    }
    /**
     * Disposes the resources of the material.
     * @param forceDisposeTextures - Forces the disposal of all textures.
     */
    dispose(forceDisposeTextures) {
        if (forceDisposeTextures) {
            this._texture?.dispose();
        }
    }
    /**
     * Get the current class name useful for serialization or dynamic coding.
     * @returns "DetailMapConfiguration"
     */
    getClassName() {
        return "DetailMapConfiguration";
    }
    /**
     * Add the required uniforms to the current list.
     * @param uniforms defines the current uniform list.
     */
    static AddUniforms(uniforms) {
        uniforms.push("vDetailInfos");
        uniforms.push("detailMatrix");
    }
    /**
     * Add the required samplers to the current list.
     * @param samplers defines the current sampler list.
     */
    static AddSamplers(samplers) {
        samplers.push("detailSampler");
    }
    /**
     * Add the required uniforms to the current buffer.
     * @param uniformBuffer defines the current uniform buffer.
     */
    static PrepareUniformBuffer(uniformBuffer) {
        uniformBuffer.addUniform("vDetailInfos", 4);
        uniformBuffer.addUniform("detailMatrix", 16);
    }
    /**
     * Makes a duplicate of the current instance into another one.
     * @param detailMap define the instance where to copy the info
     */
    copyTo(detailMap) {
        SerializationHelper.Clone(() => detailMap, this);
    }
    /**
     * Serializes this detail map instance
     * @returns - An object with the serialized instance.
     */
    serialize() {
        return SerializationHelper.Serialize(this);
    }
    /**
     * Parses a detail map setting from a serialized object.
     * @param source - Serialized object.
     * @param scene Defines the scene we are parsing for
     * @param rootUrl Defines the rootUrl to load from
     */
    parse(source, scene, rootUrl) {
        SerializationHelper.Parse(() => this, source, scene, rootUrl);
    }
}
__decorate([
    serializeAsTexture("detailTexture"),
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], DetailMapConfiguration.prototype, "texture", void 0);
__decorate([
    serialize()
], DetailMapConfiguration.prototype, "diffuseBlendLevel", void 0);
__decorate([
    serialize()
], DetailMapConfiguration.prototype, "roughnessBlendLevel", void 0);
__decorate([
    serialize()
], DetailMapConfiguration.prototype, "bumpLevel", void 0);
__decorate([
    serialize(),
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], DetailMapConfiguration.prototype, "normalBlendMethod", void 0);
__decorate([
    serialize(),
    expandToProperty("_markAllSubMeshesAsTexturesDirty")
], DetailMapConfiguration.prototype, "isEnabled", void 0);
