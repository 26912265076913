import { MaterialDefines } from '@babylonjs/core/Materials/materialDefines';
/**
 * Material Defines
 */
export class MToonMaterialDefines extends MaterialDefines {
    /**
     * If the reflection texture on this material is in linear color space
     * @hidden
     */
    // public IS_REFLECTION_LINEAR = false;
    /**
     * If the refraction texture on this material is in linear color space
     * @hidden
     */
    // public IS_REFRACTION_LINEAR = false;
    // public EXPOSURE = false;
    constructor() {
        super();
        // MToon Specific
        this.MTOON_OUTLINE_WIDTH_WORLD = false;
        this.MTOON_OUTLINE_WIDTH_SCREEN = false;
        this.MTOON_OUTLINE_COLOR_FIXED = false;
        this.MTOON_OUTLINE_COLOR_MIXED = false;
        this.MTOON_DEBUG_NORMAL = false;
        this.MTOON_DEBUG_LITSHADERRATE = false;
        // MToon textures
        this.SHADE = false;
        this.SHADEDIRECTUV = 0;
        this.RECEIVE_SHADOW = false;
        this.RECEIVE_SHADOWDIRECTUV = 0;
        this.SHADING_GRADE = false;
        this.SHADING_GRADEDIRECTUV = 0;
        this.RIM = false;
        this.RIMDIRECTUV = 0;
        this.MATCAP = false;
        this.MATCAPDIRECTUV = 0;
        this.OUTLINE_WIDTH = false;
        this.OUTLINE_WIDTHDIRECTUV = 0;
        this.UV_ANIMATION_MASK = false;
        this.UV_ANIMATION_MASKDIRECTUV = 0;
        // Misc
        this.MAINUV1 = false;
        this.MAINUV2 = false;
        this.MAINUV3 = false;
        this.MAINUV4 = false;
        this.MAINUV5 = false;
        this.MAINUV6 = false;
        this.DIFFUSE = false;
        this.DIFFUSEDIRECTUV = 0;
        this.DETAIL = false;
        this.DETAILDIRECTUV = 0;
        this.DETAIL_NORMALBLENDMETHOD = 0;
        this.AMBIENT = false;
        this.AMBIENTDIRECTUV = 0;
        // public OPACITY = false;
        // public OPACITYDIRECTUV = 0;
        // public OPACITYRGB = false;
        // public REFLECTION = false;
        this.EMISSIVE = false;
        this.EMISSIVEDIRECTUV = 0;
        this.SPECULAR = false;
        // public SPECULARDIRECTUV = 0;
        this.BUMP = false;
        this.BUMPDIRECTUV = 0;
        // public PARALLAX = false;
        // public PARALLAXOCCLUSION = false;
        // public SPECULAROVERALPHA = false;
        this.CLIPPLANE = false;
        this.CLIPPLANE2 = false;
        this.CLIPPLANE3 = false;
        this.CLIPPLANE4 = false;
        this.CLIPPLANE5 = false;
        this.CLIPPLANE6 = false;
        this.ALPHATEST = false;
        this.DEPTHPREPASS = false;
        this.ALPHAFROMDIFFUSE = false;
        this.POINTSIZE = false;
        this.FOG = false;
        this.SPECULARTERM = false;
        // public DIFFUSEFRESNEL = false;
        // public OPACITYFRESNEL = false;
        // public REFLECTIONFRESNEL = false;
        // public REFRACTIONFRESNEL = false;
        // public EMISSIVEFRESNEL = false;
        // public FRESNEL = false;
        this.NORMAL = false;
        this.TANGENT = false;
        this.UV1 = false;
        this.UV2 = false;
        this.UV3 = false;
        this.UV4 = false;
        this.UV5 = false;
        this.UV6 = false;
        this.VERTEXCOLOR = false;
        this.VERTEXALPHA = false;
        this.NUM_BONE_INFLUENCERS = 0;
        this.BonesPerMesh = 0;
        this.BONETEXTURE = false;
        this.BONES_VELOCITY_ENABLED = false;
        this.INSTANCES = false;
        this.THIN_INSTANCES = false;
        // public GLOSSINESS = false;
        // public ROUGHNESS = false;
        this.EMISSIVEASILLUMINATION = false;
        this.LINKEMISSIVEWITHDIFFUSE = false;
        // public REFLECTIONFRESNELFROMSPECULAR = false;
        // public LIGHTMAP = false;
        // public LIGHTMAPDIRECTUV = 0;
        this.OBJECTSPACE_NORMALMAP = false;
        // public USELIGHTMAPASSHADOWMAP = false;
        // public REFLECTIONMAP_3D = false;
        // public REFLECTIONMAP_SPHERICAL = false;
        // public REFLECTIONMAP_PLANAR = false;
        // public REFLECTIONMAP_CUBIC = false;
        // public USE_LOCAL_REFLECTIONMAP_CUBIC = false;
        // public USE_LOCAL_REFRACTIONMAP_CUBIC = false;
        // public REFLECTIONMAP_PROJECTION = false;
        // public REFLECTIONMAP_SKYBOX = false;
        // public REFLECTIONMAP_EXPLICIT = false;
        // public REFLECTIONMAP_EQUIRECTANGULAR = false;
        // public REFLECTIONMAP_EQUIRECTANGULAR_FIXED = false;
        // public REFLECTIONMAP_MIRROREDEQUIRECTANGULAR_FIXED = false;
        // public REFLECTIONMAP_OPPOSITEZ = false;
        // public INVERTCUBICMAP = false;
        this.LOGARITHMICDEPTH = false;
        // public REFRACTION = false;
        // public REFRACTIONMAP_3D = false;
        // public REFLECTIONOVERALPHA = false;
        this.TWOSIDEDLIGHTING = false;
        this.SHADOWFLOAT = false;
        this.MORPHTARGETS = false;
        this.MORPHTARGETS_NORMAL = false;
        this.MORPHTARGETS_TANGENT = false;
        this.MORPHTARGETS_UV = false;
        this.NUM_MORPH_INFLUENCERS = 0;
        this.MORPHTARGETS_TEXTURE = false;
        this.NONUNIFORMSCALING = false; // https://playground.babylonjs.com#V6DWIH
        this.PREMULTIPLYALPHA = false; // https://playground.babylonjs.com#LNVJJ7
        this.ALPHATEST_AFTERALLALPHACOMPUTATIONS = false;
        this.ALPHABLEND = true;
        // public PREPASS = false;
        // public PREPASS_IRRADIANCE = false;
        // public PREPASS_IRRADIANCE_INDEX = -1;
        // public PREPASS_ALBEDO = false;
        // public PREPASS_ALBEDO_INDEX = -1;
        // public PREPASS_DEPTH = false;
        // public PREPASS_DEPTH_INDEX = -1;
        // public PREPASS_NORMAL = false;
        // public PREPASS_NORMAL_INDEX = -1;
        // public PREPASS_POSITION = false;
        // public PREPASS_POSITION_INDEX = -1;
        // public PREPASS_VELOCITY = false;
        // public PREPASS_VELOCITY_INDEX = -1;
        // public PREPASS_REFLECTIVITY = false;
        // public PREPASS_REFLECTIVITY_INDEX = -1;
        // public SCENE_MRT_COUNT = 0;
        // public RGBDLIGHTMAP = false;
        // public RGBDREFLECTION = false;
        // public RGBDREFRACTION = false;
        // public IMAGEPROCESSING = false;
        // public VIGNETTE = false;
        // public VIGNETTEBLENDMODEMULTIPLY = false;
        // public VIGNETTEBLENDMODEOPAQUE = false;
        // public TONEMAPPING = false;
        // public TONEMAPPING_ACES = false;
        // public CONTRAST = false;
        // public COLORCURVES = false;
        // public COLORGRADING = false;
        // public COLORGRADING3D = false;
        // public SAMPLER3DGREENDEPTH = false;
        // public SAMPLER3DBGRMAP = false;
        // public IMAGEPROCESSINGPOSTPROCESS = false;
        this.MULTIVIEW = false;
        this.rebuild();
    }
    setReflectionMode(modeToEnable) {
        // var modes = [
        //     "REFLECTIONMAP_CUBIC", "REFLECTIONMAP_EXPLICIT", "REFLECTIONMAP_PLANAR",
        //     "REFLECTIONMAP_PROJECTION", "REFLECTIONMAP_PROJECTION", "REFLECTIONMAP_SKYBOX",
        //     "REFLECTIONMAP_SPHERICAL", "REFLECTIONMAP_EQUIRECTANGULAR", "REFLECTIONMAP_EQUIRECTANGULAR_FIXED",
        //     "REFLECTIONMAP_MIRROREDEQUIRECTANGULAR_FIXED"
        // ];
        //
        // for (var mode of modes) {
        //     (<any>this)[mode] = (mode === modeToEnable);
        // }
        throw new Error('This material cannot use `setReflectionMode`');
    }
}
