import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from 'react';
import './App.css';
import './styles/main.css';
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, StyledEngineProvider, ThemeOptions, ThemeProvider} from "@mui/material/styles";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./Home";
import Demo from "./Demo";
import Privacy from "./Privacy";

const themeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#1976d2',
            light: '#63a4ff',
            dark: '#004ba0',
        },
        secondary: {
            main: '#757575',
            light: '#a4a4a4',
            dark: '#494949',
        },
    },
};
const theme = createTheme(themeOptions);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
                <CssBaseline/>
                <Router>
                    <div>
                        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/demo" element={<Demo />} />
                            <Route path="/privacy" element={<Privacy />} />
                        </Routes>
                    </div>
                </Router>
            </StyledEngineProvider>
        </ThemeProvider>
    );
}

export default App;
