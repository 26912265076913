import { VRMManager } from './vrm-manager';
import { VRMMaterialGenerator } from './vrm-material-generator';
/**
 * VRM 拡張を処理する
 * [Specification](https://github.com/vrm-c/UniVRM/tree/master/specification/)
 */
export class VRMLoaderExtension {
    /**
     * @inheritdoc
     */
    constructor(loader, v3DCore) {
        this.loader = loader;
        this.v3DCore = v3DCore;
        /**
         * @inheritdoc
         */
        this.name = VRMLoaderExtension.NAME;
        /**
         * @inheritdoc
         */
        this.enabled = true;
        /**
         * この Mesh index 以降が読み込み対象
         */
        this.meshesFrom = 0;
        /**
         * この TransformNode index 以降が読み込み対象
         */
        this.transformNodesFrom = 0;
        /**
         * Loader observers
         */
        this.loaderObservers = [];
        // GLTFLoader has already added rootMesh as __root__ before load extension
        // @see glTFLoader._loadData
        this.meshesFrom = this.loader.babylonScene.meshes.length - 1;
        this.transformNodesFrom = this.loader.babylonScene.transformNodes.length;
        this.addLoaderObserver(this.v3DCore);
        this.onLoadedCallBack = () => {
            v3DCore.addVRMManager(this.manager);
        };
        v3DCore.addOnLoadCompleteCallbacks(this.onLoadedCallBack);
    }
    /**
     * @inheritdoc
     */
    dispose() {
        this.loader = null;
        this.loaderObservers = [];
        this.v3DCore.removeOnLoadCompleteCallback(this.onLoadedCallBack);
    }
    /**
     * @inheritdoc
     */
    onReady() {
        if (!this.loader.gltf.extensions || !this.loader.gltf.extensions[VRMLoaderExtension.NAME]) {
            return;
        }
        // Because of the way loader plugin works, this seems to be
        // the best we can do
        const uri = this.loader.parent.uri;
        this.manager = new VRMManager(this.loader.gltf.extensions[VRMLoaderExtension.NAME], this.loader.babylonScene, this.meshesFrom, this.transformNodesFrom, uri);
        this.loader.babylonScene.onDisposeObservable.add(() => {
            // Scene dispose 時に Manager も破棄する
            this.manager.dispose();
        });
        // Inform observers
        for (const observer of this.loaderObservers) {
            observer.onLoadReady();
        }
        console.log("extension onReady");
    }
    /**
     * @inheritdoc
     */
    _loadVertexDataAsync(context, primitive, babylonMesh) {
        if (!primitive.extras || !primitive.extras.targetNames) {
            return null;
        }
        // まだ MorphTarget が生成されていないので、メタ情報にモーフターゲット情報を入れておく
        babylonMesh.metadata = babylonMesh.metadata || {};
        babylonMesh.metadata.vrmTargetNames = primitive.extras.targetNames;
        return null;
    }
    /**
     * @inheritdoc
     */
    _loadMaterialAsync(context, material, mesh, babylonDrawMode, assign) {
        // ジェネレータでマテリアルを生成する
        return (new VRMMaterialGenerator(this.loader)).generate(context, material, mesh, babylonDrawMode, assign);
    }
    /**
     * Add observer
     */
    addLoaderObserver(observer) {
        this.loaderObservers.push(observer);
    }
}
/**
 * `extensions` に入る拡張キー
 */
VRMLoaderExtension.NAME = 'VRM';
